<template>
  <div>
    <div v-show="dialogVisible" @click="closeFun()" class="layer"></div>
    <div class="depthDetail" :style="{ bottom: dialogVisible ? '0' : '-1000px', height: innerHeight + 'px' }">
      <div class="depthDetail-box">
        <div class="depthDetail-header">
          <div class="depthDetail-header-detail flex">
            <p class="depthDetail-header-username bold white" style="font-size:16px;">{{ post_token ? $t('create.Edit') : $t('header.Publish') }}
            </p>
            <div style="flex:1;"></div>
            <div class="create-btn flex">
              <img class="create-btn-icon" src="@/assets/img/common/create_icon.png" alt="">
              <p class="create-btn-name" @click="createFun()">{{ post_token ? $t('create.Edit') : $t('header.Publish') }}</p>
            </div>
            <img @click="closeFun()" class="depthDetail-close" src="@/assets/img/common/common_close4.png" alt="">
          </div>
        </div>
        <div class="create">
          <el-input class="create-input" v-model="projectData.title" @input="changeValue(projectData.title, 50, 1)"
            @keyup.enter="changeValue(projectData.title, 50, 1)" :placeholder="$t('create.placeholder')"></el-input>
          <p class="Line" style="margin-top:20px;margin-bottom:50px;"></p>
          <el-input class="create-input" v-model="projectData.desc" type="textarea" :autosize="{ minRows: 1, maxRows: 4 }"
            @input="changeValue(projectData.desc, 100, 2)" @keyup.enter="changeValue(projectData.desc, 100, 2)"
            placeholder="$t('create.placeholder2')"></el-input>
          <p class="Line" style="margin-top:30px;"></p>
          <editor ref="editor" :content="projectData.content"></editor>
          <p class="Line"></p>
          <p class="create-title white"> <span>*</span> {{$t('create.msg1')}}</p>
          <p class="create-msg light">{{$t('create.msg3')}}</p>
          <el-upload class="create-upload flex" action="/api/v1/image/uploadmore" :headers="headers"
            :on-success="uploadSuccess" accept=".jpg,.jpeg,.png">
            <el-image v-if="projectData.titlepic" style="width: 190px;height: 132px;" :src="projectData.titlepic" alt=""
              fit="cover"></el-image>
            <p v-else class="create-upload-btn light">{{$t('create.picture')}}</p>
          </el-upload>
          <p class="create-title white"> <span>*</span> {{$t('create.msg2')}}</p>
          <div class="flex" style="margin-top:20px">
            <p class="create-type" :class="{ 'create-type-active': typeIndex == index }" v-for="(item, index) in typeList"
              :key="index" @click="typeChange(item, index)">{{ item.sub_category_name }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="layer" style="z-index: 93;"></div> -->
  </div>
</template>
<script>
import editor from "@/components/editor.vue"
import { getSubcategoryList, createDepth, getDepthDetail, editDepth } from '@/api/depth'
export default {
  name: "createDepth",
  props: {
    "show": { type: Boolean, default: false },
    "token": { type: String, default: '' },
  },
  components: {
    editor
  },
  data() {
    return {
      headers: {
        token: JSON.parse(localStorage.getItem('auth')) ? JSON.parse(localStorage.getItem('auth')).token : '',
        lg: localStorage.getItem('languageStorage')
      },
      innerHeight: 0,
      dialogVisible: this.show,
      imgId: '',
      typeList: [],//文章分类
      typeIndex: 0,
      projectData: {
        title: '',//标题
        desc: '',//摘要
        content: '',//内容
        titlepic: '',
        category_id: '',
        subcategory_id: ''
      },
      post_token: this.token
    };
  },
  watch: {
    show: {
      handler(newVal) {
        this.dialogVisible = newVal
      },
    },
    token: {
      handler(newVal) {
        this.post_token = newVal
        if (newVal) {
          this.getDepthDetail(newVal)
        }
      },
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight - 50
    this.getSubcategoryList()
  },
  methods: {
    closeFun() {
      if (this.post_token) {
        this.projectData = {
          title: '',//标题
          desc: '',//摘要
          content: '',//内容
          titlepic: '',
          category_id: '',
          subcategory_id: ''
        }
      }
      this.$emit("dialogClose")
    },
    //获取文章分类
    getSubcategoryList() {
      getSubcategoryList().then(res => {
        this.typeList = res.data
        this.projectData.category_id = res.data[0].category_id
        this.projectData.subcategory_id = res.data[0].id
      })
    },
    //选择文章分类
    typeChange(val, index) {
      this.typeIndex = index
      this.projectData.category_id = val.category_id
      this.projectData.subcategory_id = val.id
    },
    //验证字符长度
    changeValue(attr, num, type) {
      //1-标题 2-摘要
      let value = attr; //校验的字段
      // 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
      let maxLimitStr = num; //25个汉字
      let pass = true;
      let substringStr = "";
      let length = 0;
      let strArr = value.split("");
      strArr.map((str) => {
        if (pass) {
          if (cnReg.test(str)) {
            //中文
            length++;
          } else {
            //英文
            length += 0.5;
          }
          //大于最大限制的时候
          if (length > maxLimitStr) {
            pass = false;
            if (type == 1) {
              this.title = substringStr;
            } else if (type == 2) {
              this.abstract = substringStr
            }
          } else {
            substringStr += str;
          }
        }
      });
      return pass;
    },
    //上传头像
    uploadSuccess(res, file, fileList) {
      this.file = file;
      this.projectData.titlepic = res.data.list.url
      this.imgId = res.data.list.id
    },
    //发布文章
    createFun() {
      this.projectData.content = this.$refs.editor.html
      if (this.post_token) {
        editDepth(this.projectData).then(res => {
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          this.closeFun()
        })
      } else {
        createDepth(this.projectData).then(res => {
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.closeFun()
        })
      }
    },
    //查询文章详情
    getDepthDetail(token) {
      let data = {
        post_token: token
      }
      getDepthDetail(data).then(res => {
        this.projectData = {
          title: res.data.title,//标题
          desc: res.data.desc,//摘要
          content: res.data.content,//内容
          titlepic: res.data.titlepic,
          category_id: res.data.category_id,
          subcategory_id: res.data.subcategory_id,
          post_id:res.data.post_id
        }
        this.typeList.forEach((item,index) => {
          if(item.category_id == res.data.category_id){
            this.typeIndex = index
          }
        })
      })
    }
  },
};
</script>

<style lang="scss">
.depthDetail {
  position: fixed;
  opacity: 1;
  transition: all 0.8s ease 0.1s;
  width: 100%;
  z-index: 92;
  background: #131417;
  left: 0;

  .depthDetail-box {
    height: 100%;
    position: relative;
    overflow-y: auto;

    .depthDetail-header {
      width: 100%;
      height: 62px;
      border-bottom: 1px solid #060606;
      position: sticky;
      top: 0;
      background: #0F1014;
      z-index: 1;

      .depthDetail-header-detail {
        max-width: 1200px;
        margin: 0 auto;
        height: 62px;

        .depthDetail-header-username {
          font-size: 16px;
          margin: 0 20px 0 4px;
        }

        .create-btn {
          height: 28px;
          padding: 0 10px;
          cursor: pointer;
          background: linear-gradient(90deg, #45E6BF 0%, #1DE4B3 100%);
          border-radius: 4px;
          margin-right: 50px;

          .create-btn-icon {
            width: 16px;
            height: auto;
          }

          .create-btn-name {
            font-size: 12px;
            color: #222222;
            margin-left: 4px;
          }
        }

        .depthDetail-close {
          width: 24px;
          height: auto;
          cursor: pointer;
        }
      }
    }

    .create {
      max-width: 1200px;
      margin: 0 auto 100px auto;
      padding-top: 50px;

      .create-input {
        .el-input__inner {
          background: #131417 !important;
          border: none;
          padding: 0;
          margin: 0;
          font-size: 20px;
          color: #fff;
          font-family: 'Bold';

          &::placeholder {
            color: #6D6D7A;
            font-family: 'Normal';
          }
        }

        .el-textarea__inner {
          background: #131417 !important;
          border: none;
          padding: 0;
          margin: 0;
          font-size: 14px;
          color: #fff;

          &::placeholder {
            color: #6D6D7A;
            font-family: 'Normal';
          }
        }
      }

      .create-title {
        font-size: 14px;
        margin-top: 30px;

        span {
          color: #1DE4B3;
          line-height: 18px;
        }
      }

      .create-msg {
        font-size: 14px;
        color: #6D6D7A;
        line-height: 22px;
        margin-top: 10px;
      }

      .create-upload {
        width: 190px;
        height: 132px;
        background: #0F1014;
        border-radius: 8px;
        border: 1px solid #0D0D0E;
        margin-top: 20px;
        justify-content: center;
        overflow: hidden;

        .create-upload-btn {
          font-size: 12px;
          height: 28px;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 13px;
          line-height: 28px;
          cursor: pointer;
        }

        .create-upload-btn:active {
          color: #fff;
        }

        .el-upload-list {
          display: none !important;
        }
      }

      .create-type {
        height: 34px;
        padding: 0 20px;
        background: #252830;
        border-radius: 17px;
        backdrop-filter: blur(30px);
        line-height: 34px;
        font-size: 14px;
        color: #C6C8D1;
        cursor: pointer;
        margin-right: 30px;
        transition: all 0.3s;
      }

      .create-type-active {
        background: linear-gradient(90deg, #45E6BF 0%, #1DE4B3 100%);
        color: #222222;
      }
    }
  }

  .depthDetail-box::-webkit-scrollbar {
    display: none;
  }

}
</style>
