import Vue from "vue";
import VueI18n from 'vue-i18n'
import zn from "./zn"
import en from "./en"
// import { getQueryString } from "@/utils/getUrl";
Vue.use(VueI18n);   // 全局注册国际化包
// getQueryString('lg')
// let lg = getQueryString('lg')
// let lg = 
// sessionStorage.setItem('languageStorage', getQueryString('lg'));
// 准备翻译的语言环境信息
//8-中文  14-英文
const i18n = new VueI18n({
  locale: localStorage.getItem('languageStorage') || 'en',
  messages: {
    "zn":zn,
    "en":en
  },
  silentTranslationWarn: true
}); 
 
export default i18n