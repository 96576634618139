<template>
  <div class="demo">
    <div class="device-notification">
      <a class="device-notification--logo" href="#0">
        <img src="@/assets/img/logo.png" style="width: 92px;" alt="Global">
      </a>
      <p class="device-notification--message">Global has so much to offer that we must request you orient your device to
        portrait or find a larger screen. You won't be disappointed.</p>
    </div>

    <div class="perspective effect-rotate-left">
      <div class="container">
        <div class="outer-nav--return"></div>
        <div id="viewport" class="l-viewport">
          <div class="l-wrapper">
            <header class="header">
              <a class="header--logo" href="#0">
                <img src="@/assets/img/logo.png" style="width: 92px;" alt="Global">
              </a>
              <!-- <button class="header--cta cta">Hire Us</button> -->
              <div class="header--nav-toggle">
                <!-- <span></span> -->
                <img class="navBtn" src="@/assets/img/nav_btn.png" alt="">
              </div>
            </header>
            <nav class="l-side-nav">
              <ul class="side-nav">
                <li class="is-active"><span>Home</span>
                  <p class="line"></p>
                </li>
                <li><span>Learn To Earn</span>
                  <p class="line"></p>
                </li>
                <li><span>Creative Rewards</span>
                  <p class="line"></p>
                </li>
                <li><span>knowledge base</span>
                  <p class="line"></p>
                </li>
                <li><span>about Us</span>
                  <p class="line"></p>
                </li>
              </ul>
            </nav>
            <ul class="l-main-content main-content">
              <!-- 一屏 -->
              <!-- section--is-active section-animate -->
              <li class="l-section section">
                <div class="intro">
                  <div class="intro-detail">
                    <div class="intro-detai-box">
                      <img class="intro-detail-logo" src="@/assets/img/index/intro_logo.png" alt="">
                      <p class="intro-content">Eden X platform is an aggregation platform focusing on Web3 development
                        learning and Web3 ecological learning</p>
                      <div class="intro-btn">
                        <div class="intro-line">
                        </div>
                        <div class="intro-btn-detail" @click="goTask()">
                          <p class="intro-btn-name">Let`s Come</p>
                        <img class="arrow-icon" src="@/assets/img/index/common_icon.png" alt="">
                        </div>
                      </div>
                    </div>
                    <div class="intro-photo">
                      <img class="intro-photo1" src="@/assets/img/index/intro_bg01.png" alt="">
                      <img class="intro-photo2" src="@/assets/img/index/intro_bg02.png" alt="">
                      <div class="intro-photo-box">
                        <div class="intro-photo-detail">
                          <img class="intro-photo3" src="@/assets/img/index/intro_bg03.png" alt="">
                        </div>
                        <img class="intro-photo4" src="@/assets/img/index/intro_bg04.png" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <wave-canvas></wave-canvas>
              </li>
              <!-- 第二屏 -->
              <li class="l-section section">
                <div class="hire">
                  <div class="hire-detail">
                    <div class="hire-box">
                      <p class="hire-title">Learn To Earn</p>
                      <p class="hire-content">
                        By experiencing the joy of learning in happiness, whether it's learning development or understanding the Web3 ecosystem, it is no longer dull.
                      </p>
                    </div>
                  </div>
                  <div class="hire-bg-animation">
                    <img class="hire-bg" src="@/assets/img/index/hire_bg.png" alt="">
                  </div>
                </div>
              </li>
              <!-- 第三屏 -->
              <li class="l-section section">
                <div class="work">
                  <div class="work-detail">
                    <div class="work-l">
                      <img class="work-l-img1" src="@/assets/img/index/work_img1.png" alt="">
                      <img class="work-l-img2" src="@/assets/img/index/work_img2.png" alt="">
                      <img class="work-l-img3" src="@/assets/img/index/work_img3.png" alt="">
                      <img class="work-l-img4" src="@/assets/img/index/work_img4.png" alt="">
                      <img class="work-l-img5" src="@/assets/img/index/work_img5.png" alt="">
                      <img class="work-l-img6" src="@/assets/img/index/work_img6.png" alt="">
                      <img class="work-l-img7" src="@/assets/img/index/work_img7.png" alt="">
                    </div>
                    <div class="work-r">
                      <p class="work-r-title">Creative Rewards</p>
                      <p class="work-r-content">Users who publish articles on Move technology and the Aptos ecosystem will have the opportunity to receive rewards from the platform or readers.</p>
                      <div class="work-btn" @click="goTask()">
                        <div class="work-line"></div>
                        <div class="work-btn-detail">
                          <p class="work-btn-name">Learning Development</p>
                        <img class="arrow-icon" src="@/assets/img/index/common_icon.png" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 第四屏 -->
              <li class="l-section section">
                <div class="contact">
                  <div class="contact-detail-box">
                    <div class="contact-detail">
                      <p class="contact-title">knowledge base</p>
                      <p class="contact-content">Provide a bridge for the construction of the Aptos public chain and
                        learners to learn, solve problems, help each other and communicate</p>
                      <div class="contact-list">
                        <div class="contact-list-box">
                          <div class="contact-list-img">
                            <img class="contact-list-icon" src="@/assets/img/index/contact_icon2.png" alt="">
                          </div>
                          <p class="contact-list-name">Ask Questions</p>
                          <p class="contact-list-txt">When you learn the web3 development language, you can come to the
                            Aptos Eden knowledge base and ask your questions</p>
                          <p class="contact-list-index">01</p>
                          <div class="contact-list-layer"></div>
                        </div>
                        <div class="contact-list-box">
                          <div class="contact-list-img">
                            <img class="contact-list-icon" src="@/assets/img/index/contact_icon1.png" alt="">
                          </div>
                          <p class="contact-list-name">User Answer</p>
                          <p class="contact-list-txt">You can get help from others here, of course you can also help
                            others in the knowledge base and get points</p>
                          <p class="contact-list-index">02</p>
                          <div class="contact-list-layer"></div>
                        </div>
                        <div class="contact-list-box">
                          <div class="contact-list-img">
                            <img class="contact-list-icon" src="@/assets/img/index/contact_icon2.png" alt="">
                          </div>
                          <p class="contact-list-name">AI Reply</p>
                          <p class="contact-list-txt">If you want to know the answer urgently for a short time, don't be
                            afraid, AI will help you solve the problem!</p>
                          <p class="contact-list-index">03</p>
                          <div class="contact-list-layer"></div>
                        </div>
                        <div class="contact-list-box">
                          <div class="contact-list-img">
                            <img class="contact-list-icon" src="@/assets/img/index/contact_icon3.png" alt="">
                          </div>
                          <p class="contact-list-name">Reward</p>
                          <p class="contact-list-txt">Have the opportunity to receive rewards from the platform or readers.</p>
                          <p class="contact-list-index">04</p>
                          <div class="contact-list-layer"></div>
                        </div>
                      </div>
                      <div class="contact-btn" @click="goTask()">
                        <div class="contact-line"></div>
                        <div class="contact-btn-detail">
                          <p class="contact-btn-name">Let's Go</p>
                        <img class="arrow-icon" src="@/assets/img/index/common_icon.png" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="l-section section">
                <div class="end">
                  <star-canvas></star-canvas>
                  <div class="end-detail">
                    <p class="end-title">Eden X and our partners</p>
                    <p class="end-content">The Aptos Eden platform aims to help Web2 users deeply understand and learn
                      Move development technology and use Aptos ecological tools. It is committed to becoming a bridge
                      bridging the Web2 and Web3 worlds and providing strong support for the development of the Aptos
                      field.</p>
                    <banner-view></banner-view>
                  </div>
                  <div class="end-share">
                    <!-- <a href=""><img class="end-share-icon" src="@/assets/img/index/email_icon.png" alt=""></a> -->
                    <a href="https://discord.gg/R5E3sUCrTC" target="_blank"><img class="end-share-icon" src="@/assets/img/index/Discord_icon.png" alt=""></a>
                    <a href="https://twitter.com/AptosEden" target="_blank"><img class="end-share-icon" src="@/assets/img/index/Twitter_icon.png" alt=""></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ul class="outer-nav">
        <li class="is-active">Home</li>
        <li>Learn To Earn</li>
        <li>Creative Rewards</li>
        <li>knowledge base</li>
        <li>about Us</li>
      </ul>
    </div>
    <!-- <mouse-view></mouse-view> -->
    <!-- <loading-view></loading-view> -->
  </div>
</template>
<script>
import waveCanvas from "@/components/waveCanvas.vue";
import starCanvas from "@/components/starCanvas.vue";
import bannerView from "@/components/banner.vue";
// import mouseView from "@/components/mouse.vue";
import loadingView from "@/components/loading.vue";
export default {
  name: "demo",
  components: {
    waveCanvas,
    starCanvas,
    bannerView,
    // mouseView,
    loadingView
  },
  data() {
    return {
    };
  },
  watch: {

  },
  mounted() {
    import('../assets/js/vendor/jquery-2.2.4.min.js').then(external => {
      // 外部JavaScript文件加载完成后的逻辑
    });
    import('../assets/js/functions.js').then(external => {
      // 外部JavaScript文件加载完成后的逻辑

      $('.main-content').children().removeClass('section--is-active');
      $('.main-content').children().removeClass('section-animate');
      $('.main-content').children().eq(0).addClass('section--is-active');
      $('.main-content').children().eq(0).addClass('section-animate');
    });
  },
  methods: {
    goTask() {
      this.$router.push('/task').catch(err => { })
    }
  },
};
</script>

<style lang="scss">@import '@/assets/css/main.css';
@import '@/assets/css/index.scss';

body {
  color: #fff;
  // overflow: hidden;
}</style>
