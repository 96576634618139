import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css'; 
import VueI18n from 'vue-i18n'
import i18n from '@/assets/lang/lang.js'
import './assets/font/font.css'
import $ from 'jquery'
import VueLazyload from 'vue-lazyload'
window.jQuery = $;
window.$ = $;
// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueI18n)
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('./assets/loadLazy/loading.svg'),
  loading: require('./assets/loadLazy/loading.svg'),
  attempt: 1
  })
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
