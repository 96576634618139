<script>
import { wLogin } from '@/api/api'
import { Message } from "element-ui";
import Store from '@/store'
export default {
  async petraFun() {
    const isPetraInstalled = window.aptos;
    // console.log('打印信息')
    // console.log(isPetraInstalled)
    const getAptosWallet = () => {
      if ('aptos' in window) {
        return window.aptos;
      } else {
        // window.open('https://petra.app/', `_blank`); 
        // window.open('https://chrome.google.com/webstore/detail/petra-aptos-wallet/ejjladinnckdgjemekebdpeokbikhfci?hl=encategory=extensions', `_blank`);
        this.petraShow = true
      }
    };
    const wallet = getAptosWallet();
    // wallet.disconnect();
    try {
      const response = await wallet.connect();
      this.Login(response.address)
    } catch (error) {
      if (error.code == 4000) {
        this.$message.error('未找到账户');
      } else if (error.code == 4001) {
        this.$message.error('用户拒绝了请求');
      } else if (error.code == 4100) {
        this.$message.error('请求的方法和/或帐户尚未获得用户授权');
      }
    }
  },
  //登录
  Login(address) {
    let data = {
      address: address
    }
    wLogin(data).then(res => {
      localStorage.setItem("auth", JSON.stringify(res.data));
      Message({
        message: "Login succeeded",
        type: "success",
        duration: 3 * 1000
      });
      Store.commit('GetUserInfo') //显示登录提示
    })
  },
}
</script>