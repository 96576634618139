<template>
  <div>
    <div v-show="baseShow" @click="closeFun()" class="layer"></div>
    <div class="depthDetail" :style="{ bottom: baseShow ? '0' : '-1000px', height: innerHeight + 'px' }">
      <div class="depthDetail-box">
        <base-header :stateType="stateType" @user="stateType = 1" @eden="stateType = 0"></base-header>
        <article v-if="stateType == 0" class="new_article">
          <div class="new_container" v-loading="loadingFirst">
            <div class="article">
              <div class="article-nav flex">
                <p class="article-nav-txt" :class="{ 'article-nav-active': sortIndex == 0 }" @click="sortIndex = 0">
                  {{ $t('base.Default') }}
                </p>
                <!-- <div class="flex" style="margin-left:50px;" :class="{ 'article-nav-active': sortIndex == 1 }"
                  @click="sortIndex = 1">
                  <p class="article-nav-txt">难度</p>
                  <img v-if="sortIndex == 1" class="article-nav-icon" src="@/assets/img/medium/arrow_icon2.png" alt="">
                  <img v-else class="article-nav-icon" src="@/assets/img/medium/arrow_icon.png" alt="">
                </div>
                <div class="flex" style="margin-left:50px;" :class="{ 'article-nav-active': sortIndex == 2 }"
                  @click="sortIndex = 2">
                  <p class="article-nav-txt">参与人数</p>
                  <img v-if="sortIndex == 2" class="article-nav-icon" src="@/assets/img/medium/arrow_icon2.png" alt="">
                  <img v-else class="article-nav-icon" src="@/assets/img/medium/arrow_icon.png" alt="">
                </div> -->
                <p style="flex:1;"></p>
                <!-- <p class="article-nav-txt">当前题目：<span>1972道</span></p> -->
                <!-- <p class="article-nav-txt" style="margin-left:48px;">参与解题人数：<span>19872</span></p> -->
              </div>
              <div class="block">
                <div v-for="item in itemByDateTop" :key="item.id">
                  <div class="items">
                    <div class="block-left block-hot">
                      <div class="flex">
                        <h2 class="itemTitle" @click="gotoDetails(item)">{{ item.title_en }}</h2>
                        <img class="block-left-icon" src="@/assets/img/medium/top_icon.png" alt="">
                      </div>
                      <div class="blogtext">
                        <p v-html="item.code_snippet"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="item in itemByDate" :key="item.id">
                  <div class="items unTop">
                    <div class="block-left" @click="gotoDetails(item)">
                      <h2 class="itemTitle">{{ item.title_en }}</h2>
                      <div class="item_info_box">
                        <img class="item_info_box_img" :src="item.userpic" alt="">
                        <span>{{ item.username ? item.username : '' | nameFilter }}</span>
                        <span class="address">{{ item.userpic | nameFilter }}</span>
                        <p style="flex:1;"></p>
                        <img class="item_info_box_copy" src="@/assets/img/medium/next.png" alt="">
                      </div>
                      <p class="blogtext">Participant: {{ item.kb_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="isMore">
              <div class="loadContent" @click="loadContent" v-if="!isEnd && !loading">To load more …</div>
              <div class="loadContent" v-if="!isEnd && loading">Load...</div>
              <span v-if="isEnd" class="isLoadEnd">End~</span>
            </div>
          </div>
          <div class="body_right">
            <div v-if="userInfo" class="personal">
              <div @click="gotoUser(1)" class="head_info flex">
                <img :src="userInfo.userpic" alt="" class="headPic">
                <p class="name bold">{{ userInfo.username ? userInfo.username : '' | nameFilter }}</p>
              </div>
              <p class="line"></p>
              <div class="info_box">
                <div class="item">
                  <p>{{ userInfo.kb }}</p>
                  <p>{{ $t('base.Questions') }}</p>
                </div>
                <div class="item">
                  <p>{{ userInfo.ka }}</p>
                  <p>{{ $t('base.Answers') }}</p>
                </div>
                <!-- <div class="item">
                  <p>82</p>
                  <p>{{$t('base.Comments')}}</p>
                </div> -->
              </div>
              <div class="flex" style="justify-content:space-between;">
                <div class="enter_btn" @click="dialogVisible = true">
                  {{ $t('base.Publish') }}
                </div>
              </div>
            </div>
            <div class="link_list">
              <p class="link_tit">
                <img src="@/assets/img/medium/tit3.png" alt="">
                <span>Aptos Document</span>
              </p>
              <div v-for="item in linkList" :key="item.id">
                <a v-if="item.na_id == 7" class="link_item" :href="item.url" target="_blank">
                  {{ item.title }}
                </a>
              </div>
              <!-- biaoti  -->
              <p class="link_tit">
                <img src="@/assets/img/medium/tit2.png" alt="">
                <span>Developer Tools</span>
              </p>
              <div v-for="item in linkList" :key="item.id">
                <a v-if="item.na_id == 8" class="link_item" :href="item.url" target="_blank">
                  {{ item.title }}
                </a>
              </div>
            </div>
            <div class="link_list">
              <p class="link_tit">
                <img src="@/assets/img/medium/tit1.png" alt="">
                <span>Move Community</span>
              </p>
              <div v-for="item in linkList" :key="item.id">
                <a v-if="item.na_id == 9" class="link_item" :href="item.url" target="_blank">
                  {{ item.title }}
                </a>
              </div>
            </div>
          </div>


          <div style="height:80px;width: 1px;"></div>
        </article>
        <!-- 我的知识库 -->
        <div v-if="stateType == 1">
          <base-user @detail="userDetail"></base-user>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="发布问题" :visible.sync="dialogVisible" custom-class="questionDialog" top="100px">
      <el-input v-model="title" autofocus="true" placeholder="Please enter a title" class="qContent"></el-input>
      <div class="el-textarea-box">
        <el-input type="textarea" :rows="8" resize="none" placeholder="Please enter a problem description"
          v-model="content" class="qContent1">
        </el-input>
        <p class="el-textarea-line"></p>
        <el-input type="textarea" :rows="8" resize="none" placeholder="Please enter the code required for the question"
          v-model="code_snippet" class="qContent1">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <!-- <el-button type="primary">确 定</el-button> -->
        <div class="primary_btn" @click="createQuestion">Submit </div>
        <div class="primary_btn" @click="dialogVisible = false">Cancel </div>
      </span>
    </el-dialog>
    <!-- 提问成功 -->
    <div v-show="questionState">
      <div style="z-index: 94;" class="layer"></div>
      <div class="question-layer">
        <img class="question-layer-close" src="@/assets/img/common/common_close.png" alt="">
        <img class="question-layer-icon" src="@/assets/img/common/success_icon.png" alt="">
        <p class="question-layer-title Medium white">提问成功</p>
        <p class="question-layer-msg">您可以点击知识库中的我的头像，查看您的提问</p>
        <div class="question-layer-footer flex">
          <p class="question-layer-btn" style="background: #252830;margin-right:20px;">再次提问</p>
          <p class="question-layer-btn" style="background: #1EE9B7;color:#222222;">完成</p>
        </div>
      </div>
    </div>
    <medium-details :show="detailShow" @dialogClose="detailShow = false, kb_id = 0" :id="kb_id"></medium-details>
  </div>
</template>

<script>
import baseHeader from "@/components/baseHeader.vue";
import mediumDetails from "@/views/base/mediumDetails.vue";
import baseUser from "@/views/base/baseUser.vue";
import { getDepthList } from '@/api/depth'
import { getMediumList, createQuestion, getUserInfo, getMediumTopList, getLinksList } from '@/api/medium'
export default {
  filters: {
    nameFilter(name) {
      if (name.length > 10) {
        let a = name.slice(0, 3)
        let b = name.slice(7, 10)
        return a + '****' + b;
      } else {
        return name;
      }

    }
  },
  props: {
    "show": { type: Boolean, default: false }
  },
  data() {
    return {
      stateType: 0,//0-知识库 1-我的知识库
      baseShow: this.show,
      itemByDate: [],
      itemByDateTop: [],
      linkList: [],
      select_type: 0,
      count: 0,
      currentPage: 1,
      pageSize: 10,
      totalPage: 2,
      userInfo: '',
      isEnd: false,//是否到底
      loading: false,//加载中
      loadingFirst: false,
      class_id: 1,
      sort: 1,
      dialogVisible: false,
      title: '',
      content: '',
      code_snippet: '',
      // type: 
      innerHeight: 0,
      sortIndex: 0, //排序
      kb_id: 0,
      questionState: false, //提问成功与否
      detailShow: false, //详情
    }
  },
  components: {
    // 注册组件
    baseHeader, mediumDetails, baseUser
  },
  computed: {
    isUserInfo() {
      this.userInfo = this.$store.state.userInfo
      return this.$store.state.userInfo
    }
  },
  watch: {
    show: {
      handler(newVal) {
        this.baseShow = newVal
        this.getMediumList(1)
        this.getLinksList()
        this.getMediumTopList()
        if (this.userInfo) {
          this.getUserInfo()
        }
      },
    },
    isUserInfo: {
      handler(nval) {
        this.userInfo = nval
      }
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight
    // this.connectWallet()
  },
  created() {
    //   this.getList(this.currentPage)
  },
  methods: {
    async connectWallet() {
      const response = await window.aptos.connect()
      //   console.log("connectWallet===",response.address)
    },
    getMediumTopList() {
      getMediumTopList().then(res => {
        this.itemByDateTop = res.data
      })
    },
    getLinksList() {
      getLinksList().then(res => {
        this.linkList = res.data
      })
    },
    getMediumList(page) {
      let data = {
        page: page,
        pageSize: 10,
      }
      getMediumList(data).then(res => {
        // this.itemByDate = res.data.data
        if (page == 1) {
          this.itemByDate = res.data.data
        } else {
          this.itemByDate = [...this.itemByDate, ...res.data.data]
        }

        this.totalPage = res.data.totalPage
        if (this.currentPage == this.totalPage) {
          this.isEnd = true
        }
        // console.log( this.itemByDate )

      })
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.userInfo = res.data
      })
    },
    createQuestion() {
      let data = {
        title: this.title,
        content: this.content,
        code_snippet: this.code_snippet
      }
      if (this.title.length == 0 || this.content.length == 0) {
        this.$message.error('The input content cannot be empty')
        return
      }
      createQuestion(data).then(res => {
        // console.log( res)
        this.$message.success('success')
        this.getMediumList(1)
        this.dialogVisible = false

        this.title = ''
        this.content = ''
        this.code_snippet = ''

      })
    },
    getList(page) {
      let data = {
        page: page,
        type: this.select_type,
        class_id: this.class_id,
        sort: 0,
      }
      this.loading = true
      getDepthList(data).then(res => {
        this.loading = false
        this.loadingFirst = false
        if (page == 1) {
          this.itemByDate = res.data.data
          console.log(this.itemByDate)
        } else {
          this.itemByDate = [...this.itemByDate, ...res.data.data]
        }

        this.totalPage = res.data.totalPage
        if (this.currentPage == this.totalPage) {
          this.isEnd = true
        }
        // console.log(res)
      })
    },
    loadContent() {
      this.currentPage++
      this.getMediumList(this.currentPage)
    },
    gotoDetails(item) {
      this.kb_id = item.kb_id
      this.detailShow = true
    },
    userDetail(id) {
      this.kb_id = id
      this.detailShow = true
    },
    gotoUser(id) {
      this.$router.push({ path: "/personalCenter", query: {} });
    }
    ,
    changeSelect(num) {
      if (this.select_type != num) {
        this.itemByDate = []
        this.select_type = num
        this.currentPage = 1
        this.totalPage = 2
        this.stateType = 0
        this.getList(1)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.depthDetail {
  position: fixed;
  opacity: 1;
  transition: all 0.8s ease 0.1s;
  width: 100%;
  z-index: 92;
  background: #131417;
  left: 0;

  .depthDetail-box {
    height: 100%;
    position: relative;
    overflow-y: auto;
  }
}

.new_article {
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
}

.body_right {
  // position: fixed;
  // margin-left: 840px;
  margin-top: 30px;
  float: right;

  // margin-top: 40px;
  .link_list {
    width: 300px;
    background: #1E1F26;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .link_tit {
      img {
        width: 18px;
        height: auto;
        display: inline-block;
        margin-right: 10px;
        position: relative;
        top: 3px;
      }

      span {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 40px;
      }
    }

    .link_item {
      font-size: 14px;
      text-indent: 28px;
      color: #6D6D7A;
      display: inline-block;
      margin-bottom: 15px;
      width: 100%;
      transition: all 0.3s;

      &:hover {
        color: #1DE4B3;
      }
    }
  }
}

.personal {
  height: 235px;
  width: 300px;
  background: #1E1F26;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;

  .head_info {
    cursor: pointer;
    height: 42px;
  }

  .headPic {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }

  .name {
    color: #fff;
    font-size: 20px;
    line-height: 20px;
  }

  .line {
    border-bottom: 1px solid #121317;
    margin: 20px 0;
  }

  .info_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .item {
      width: 48%;

      p:first-child {
        font-size: 16px;
        text-align: center;
        color: #1DE4B3;
        margin-bottom: 6px;
      }

      p:last-child {
        color: #FFFFFF;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .enter_btn {
    cursor: pointer;
    width: 250px;
    height: 42px;
    background: linear-gradient(140deg, #9AFBE1 0%, #01D199 100%);
    box-shadow: 0px 0px 9px 3px rgba(6, 6, 13, 0.11), inset 1px 1px 4px 0px rgba(229, 253, 255, 0.44);
    border-radius: 10px;
    font-size: 14px;
    color: #222222;
    text-align: center;
    line-height: 42px;
  }
}

.new_article {
  position: relative;
  overflow: visible;
  width: 1200px;
  margin: 0 auto;
}

.block_box {
  width: 760px;
  margin: 0 auto;
  margin-top: 100px;
  // margin-bottom: 30px;
}

.isMore {
  width: 760px;
  margin: 30px auto;
  height: 36px;
  cursor: pointer;

  .loadContent {
    background-color: #030303;
    font-size: 14px;
    color: #1DE7B5;
    text-align: center;
    line-height: 36px;
  }

  .isLoadEnd {
    font-size: 14px;
    color: #999;
    text-align: center;
    line-height: 36px;
    display: block;
    width: 100%;
  }
}

.article {
  background: #1E1F26;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  margin-top: 30px;
}

.block {
  width: 820px;
  margin: 0 auto;
  min-height: 400px;

  .items {
    margin-top: 20px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .unTop {
    overflow: hidden;

    .listIcon {
      height: 100%;
      display: flex;
      justify-content: center;

      img {
        width: 16px;
        height: auto;
      }
    }
  }

  .itemTitle {
    padding: 0;
    margin: 0;
    font-size: 18px;
    color: #fff;
    line-height: 20px;
  }

  .blogtext {
    color: #9898A8;
    font-size: 12px;
    line-height: 16px;
    word-wrap: break-word;
  }

  .block-left {
    width: 820px;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #18191F;
    border-radius: 10px;
    border: 1px solid #0F1014;
    cursor: pointer;
    transition: all 0.3s;
  }

  .block-left:hover {
    background: #2C2E3A;
  }

  .block-hot {
    background: #111217;

    .itemTitle {
      flex: 1;
    }

    .block-left-icon {
      width: 44px;
      height: auto;
    }

    .blogtext {
      margin-top: 20px;
      line-height: 20px;
      font-size: 14px;
    }
  }

  .contentPic {
    width: 210px;
    height: 124px;
    border-radius: 4px;
  }

  .item_info_box {
    display: flex;
    align-items: center;
    margin: 16px 0 20px 0;

    .item_info_box_img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
    }

    span {
      color: #DBDCE4;
      font-size: 12px;
      margin: 0 10px;
    }

    .address {
      line-height: 24px;
      border-radius: 12px;
      padding: 0 10px;
      background: #2C2E3D;
      color: #9898A8;
    }

    .item_info_box_copy {
      width: 24px;
      height: auto;
    }
  }

}

.qContent {
  margin-bottom: 20px;
}

.qContent1 {
  margin-bottom: 4px;
}

.primary_btn {
  float: right;
  width: 120px;
  height: 40px;
  margin-left: 30px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #222222;
  border-radius: 8px;
  background: #1DE4B3;
  cursor: pointer;
}

/deep/.dialog-footer,
/deep/.el-dialog__footer {
  overflow: hidden;
}

/deep/.questionDialog {
  background: #1E1F26;
  background-color: #1E1F26;
  width: 760px;

  .el-dialog__title {
    color: #fff;
  }

  .el-dialog__close {
    color: #fff;
  }

  .el-textarea__inner,
  .el-input__inner {
    background-color: #111217;
    border: none;
    color: #FFFFFF;
    border-radius: 12px;
    padding: 14px 20px;
    font-size: 14px;
  }
}

.el-textarea-box {
  width: 720px;
  height: 528px;
  background: #111217;
  border-radius: 12px;
  padding: 6px 0;

  .el-textarea-line {
    height: 1px;
    border: 1px solid #1E1F26;
    margin: 0 20px;
  }
}

.article-nav {
  .article-nav-txt {
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.3s;

    span {
      color: #1DE4B3;
    }
  }

  .article-nav-icon {
    width: 7px;
    height: auto;
    margin-left: 8px;
    cursor: pointer;
  }
}

.article-nav-active {
  color: #1DE4B3 !important;
}

.question-layer {
  width: 400px;
  height: 276px;
  background: #131417;
  box-shadow: 0px 0px 22px 3px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  border: 1px solid #0B0B0B;
  position: fixed;
  z-index: 95;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -220px;
  text-align: center;

  .question-layer-close {
    width: 10px;
    height: auto;
    display: block;
    margin: 16px 16px 14px auto;
    cursor: pointer;
  }

  .question-layer-icon {
    width: 52px;
    height: auto;
  }

  .question-layer-title {
    font-size: 16px;
    line-height: 22px;
    margin: 20px auto 10px auto;
  }

  .question-layer-msg {
    font-size: 14px;
    line-height: 20px;
    color: #8C8C9E;
  }

  .question-layer-footer {
    margin-top: 30px;
    justify-content: center;

    .question-layer-btn {
      width: 160px;
      height: 42px;
      border-radius: 12px;
      opacity: 0.9;
      backdrop-filter: blur(36px);
      cursor: pointer;
      font-size: 14px;
      line-height: 42px;
    }
  }
}
</style>
