<template>
    <div>
        <div v-show="dialogVisible" @click="closeFun()" class="layer" style="z-index: 95;"></div>
        <div class="baseDetail" :style="{ bottom: dialogVisible ? '0' : '-1000px', height: innerHeight + 'px' }">
            <div class="baseDetail-box" v-on:scroll="scrollHandler">
                <div class="baseDetail-header">
                    <div class="baseDetail-header-detail flex">
                        <p class="baseDetail-header-username bold white" style="font-size: 16px;">{{ $t('base.Questions2')
                        }}
                        </p>
                        <div style="flex:1;"></div>
                        <div class="write flex">
                            <img class="write-icon" src="@/assets/img/medium/write.png" alt="">
                            <p class="write-title" @click="writeFun">
                                {{ $t('base.Solutions2') }}</p>
                        </div>
                        <img @click="closeFun()" class="baseDetail-close" src="@/assets/img/common/common_close4.png"
                            alt="">
                    </div>
                </div>
                <div class="baseDetail-detail">
                    <p class="baseDetail-title white">
                        {{ desInfo.title_en }}
                    </p>
                    <div class="baseDetail-user flex">
                        <img class="baseDetail-user-img" :src="desInfo.userpic" alt="">
                        <p class="baseDetail-user-name white">{{ desInfo.username }} · <span>{{ desInfo.create_time
                        }}</span>
                        </p>
                    </div>
                    <div class="baseDetail-brief light">
                        {{ desInfo.content_en }}
                    </div>
                    <div class="baseDetail-content light" :style="{ height: contentState ? 'auto' : '60px' }">
                        <p v-show="contentState">{{ desInfo.code_snippet }}</p>
                        <p v-show="!contentState">{{ $t('common.viewAll') }}</p>
                        <img v-show="!contentState" @click="contentState = !contentState" class="baseDetail-all"
                            src="@/assets/img/medium/brokenAll.png" alt="">
                        <img v-show="contentState" @click="contentState = !contentState" class="baseDetail-all"
                            src="@/assets/img/medium/abbreviation.png" alt="">
                    </div>
                    <div :style="{ height: replyState ? '560px' : '0px' }" class="replay-box">
                        <div class="editor-box">
                            <el-input class="replay-input" type="textarea" :rows="20" placeholder="写回答…"
                                v-model="replyContent">
                            </el-input>
                            <p class="replay-button" @click="answer"
                                :class="{ 'replay-button-active': replyContent != '' }">{{ $t('base.PublishAnswers') }}</p>
                        </div>
                    </div>
                    <p class="Line"></p>
                    <div class="flex baseDetail-reply">
                        <p class="baseDetail-reply-title">{{ $t('base.Solutions2') }}</p>
                        <img @click="getAI" class="baseDetail-reply-ai" src="@/assets/img/medium/edenAI.png" alt="">
                    </div>
                    <!-- AI -->
                    <transition name="el-zoom-in-top">
                        <div v-show="AiContent" class="baseDetail-reply-list" style="border: 1px solid #7873FA;">
                            <div class="flex">
                                <img class="baseDetail-user-img" :src="AiContent.userpic" alt="">
                                <p class="baseDetail-user-name white">{{ AiContent.username }} · <span>{{
                                    AiContent.create_time
                                }}</span>
                                </p>
                                <div class="flex" @click="RewardFun(AiContent, 1)">
                                    <img class="baseDetail-reply-list-icon" src="@/assets/img/medium/Reward_icon.png"
                                        alt="">
                                    <p class="baseDetail-reply-list-reward">{{ $t('common.Reward') }}</p>
                                </div>
                            </div>
                            <p class="baseDetail-reply-list-content light text2">
                                <!-- {{ AiContent.content }} -->
                            </p>
                        </div>
                    </transition>
                    <div>
                        <div v-for="(item, index) in desList" :key="index" class="baseDetail-reply-list">
                            <div class="flex">
                                <img class="baseDetail-user-img" :src="item.userpic" alt="">
                                <p class="baseDetail-user-name white">{{ item.username }} · <span>{{ item.create_time
                                }}</span>
                                </p>
                                <div class="flex" @click="RewardFun(item)">
                                    <img class="baseDetail-reply-list-icon" src="@/assets/img/medium/Reward_icon.png"
                                        alt="">
                                    <p class="baseDetail-reply-list-reward">{{ $t('common.Reward') }}</p>
                                </div>
                            </div>
                            <div class="baseDetail-reply-list-content light">
                                <!-- <p class="baseDetail-reply-list-all">查看全部</p> -->
                                {{ item.content }}
                            </div>
                        </div>
                        <p class="baseDetail-reply-more light">{{ $t('base.msg1') }}</p>
                    </div>
                </div>
                <!-- 标题 -->
                <transition name="el-zoom-in-top">
                    <div v-show="titleState" class="top-title flex">
                        <p class="top-title-content overflow">
                            These instructions have been tested on macOS Monterey (12.6) Ensure you have git installed
                            https://git-scm.com/book/en/git-scm.com/book/en/
                        </p>
                    </div>
                </transition>
            </div>
        </div>
        <div>
            <div v-show="rewardState" @click="rewardState = false, detail = {}" class="layer" style="z-index: 97;"></div>
            <transition name="el-fade-in-linear">
                <div v-show="rewardState" class="reward-detail">
                    <img class="reward-detail-img" :src="detail.userpic" alt="">
                    <p class="reward-detail-name Medium white">
                        {{ detail.username ? detail.username : '' | nameFilter }}
                        <img class="reward-detail-close" @click="rewardState = false, detail = {}"
                            src="@/assets/img/common/common_close.png" alt="">
                    </p>
                    <p class="reward-detail-msg light">{{ $t('depth.Thanks') }}</p>
                    <div class="reward-detail-box flex">
                        <el-input class="reward-detail-input" v-model="amount"
                            :placeholder="$t('depth.placeholder1')"></el-input>
                        <p class="reward-detail-unit light white">APT</p>
                    </div>
                    <div class="reward-detail-footer flex">
                        <p @click="rewardState = false" class="reward-detail-btn reward-detail-cancel">{{
                            $t('common.Cancel') }}</p>
                        <p @click="confirmFun" class="reward-detail-btn reward-detail-confirm">{{ $t('common.Confirm') }}
                        </p>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import {
    getKnowledgeDetail, getAnswerDetail, createAnswer, getAiList,
    postQuestion, upErrorAi
} from '@/api/medium'
import PetraLogin from '@/components/PetraLogin';
import { mapMutations } from "vuex";
import { addAward } from '@/api/depth'
export default {
    name: "baseDetail",
    filters: {
        nameFilter(name) {
            if (name.length > 10) {
                let a = name.slice(0, 4)
                let b = name.slice(62, 66)
                return a + '****' + b;
            } else {
                return name;
            }
        },
    },
    props: {
        "show": { type: Boolean, default: false },
        "id": { type: Number, default: 0 },
    },
    components: {
    },
    data() {
        return {
            innerHeight: 0,
            dialogVisible: this.show,
            rewardState: false,
            kb_id: this.kb_id,
            desInfo: {},
            desList: {},
            titleState: false, //置顶标题
            contentState: false, //内容
            replyContent: '',//回复内容
            replyState: false,
            amount: '', //打赏金额
            rewardState: false,
            detail: {},//被打赏人信息
            AiContent: '',
            timer: '',
            userInfo: {}
        };
    },
    watch: {
        show: {
            handler(newVal) {
                this.dialogVisible = newVal
            },
        },
        id: {
            handler(newVal) {
                this.kb_id = newVal
                if (newVal != 0) {
                    this.getInfo(this.kb_id)
                    this.getAnswerList(this.kb_id)
                }
            },
        },
        isUserInfo: {
            handler(nval) {
                this.userInfo = nval
            }
        }
    },
    computed: {
        isUserInfo() {
            this.userInfo = this.$store.state.userInfo
            return this.$store.state.userInfo
        }
    },
    mounted() {
        this.innerHeight = window.innerHeight - 50
    },
    methods: {
        ...mapMutations(["GetUserInfo"]),
        scrollHandler(event) {
            if (event.target.scrollTop > 200) {
                this.titleState = true
            } else if (event.target.scrollTop < 200) {
                this.titleState = false
            }
        },
        closeFun() {
            this.$emit("dialogClose")
            this.titleState = false
            this.AiContent = ''
            this.replyState = false
        },
        getInfo(id) {
            let data = {
                k_id: id
            }
            getKnowledgeDetail(data).then(res => {
                this.desInfo = res.data
            })
        },
        getAnswerList(id) {
            let data = {
                k_id: id
            }
            getAnswerDetail(data).then(res => {
                this.desList = res.data
            })
        },
        //写回答
        answer() {
            if (this.replyContent == '') {
                this.$message.error('请输入回答内容');
                return
            }
            let data = {
                k_id: this.kb_id,
                content: this.replyContent
            }
            createAnswer(data).then(res => {
                this.getAnswerList(this.kb_id)
                this.replyContent = ''
                this.replyState = false
            })
        },
        //打赏
        confirmFun() {
            if (!this.$store.state.userInfo) {
                this.$message.error('请登录');
            }
            if (!this.userInfo) {
                PetraLogin.petraFun()
                return
            }
            if (!this.amount) {
                this.$message.error('请输入打赏金额');
                return
            }
            this.rewardAptos()
        },
        async rewardAptos() {
            const isPetraInstalled = window.aptos;
            let address = this.detail.address
            const getAptosWallet = () => {
                if ('aptos' in window) {
                    return window.aptos;
                } else {
                    // window.open('https://petra.app/', `_blank`); 
                    window.open('https://chrome.google.com/webstore/detail/petra-aptos-wallet/ejjladinnckdgjemekebdpeokbikhfci?hl=encategory=extensions', `_blank`);
                    // this.petraShow = true
                }
            };
            const wallet = getAptosWallet();
            try {
                const response = await wallet.connect();
            } catch (error) {
                if (error.code == 4000) {
                    this.$message.error('未找到账户');
                } else if (error.code == 4001) {
                    this.$message.error('用户拒绝了请求');
                } else if (error.code == 4100) {
                    this.$message.error('请求的方法和/或帐户尚未获得用户授权');
                }
            }
            //发送请求
            const transaction = {
                arguments: [address, parseInt(this.amount)],
                function: '0x1::coin::transfer',
                type: 'entry_function_payload',
                type_arguments: ['0x1::aptos_coin::AptosCoin'],
            };

            try {
                const pendingTransaction = await (
                    window
                ).aptos.signAndSubmitTransaction(transaction);
                this.addAward()
                // In most cases a dApp will want to wait for the transaction, in these cases you can use the typescript sdk
                const client = new AptosClient('https://devnet.aptoslabs.com');
                const txn = await client.waitForTransactionWithResult(
                    pendingTransaction.hash,
                );
            } catch (error) {
                // see "Errors"
            }
        },
        //打赏回调
        addAward() {
            let data = {
                to_address: this.detail.address,
                from_address: this.$store.state.userInfo.address,
                award: this.amount
            }
            addAward(data).then(res => {
                this.$message({
                    message: '打赏成功',
                    type: 'success'
                });
                this.amount = ''
                this.rewardState = false
                this.detail = {}
            })
        },
        RewardFun(val, type) {
            if (!this.userInfo) {
                PetraLogin.petraFun()
                return
            }
            this.detail = val
            this.rewardState = true
            if (type == 1) {
                this.detail.address = '0x524578991089b9ed0c77bc450250a59ebe03a5b744cef9406c9de6eec79925ae'
            }
        },
        getAI() {
            let data = {
                title: this.desInfo.title_en,
                description: this.desInfo.content_en,
                code: this.desInfo.code_snippet,
                kb_id: this.kb_id
            }
            if (this.desInfo.ai_status == 0) {
                postQuestion(data).then(res => {
                    this.desInfo.ai_status = 1
                    this.getAI()
                })
                return
            }
            getAiList(data).then(res => {
                this.AiContent = res.data
                this.main(res.data.content)
            })
        },
        //文字打印机效果
        main(str) {
            var num = 1
            this.timer = setInterval(() => {
                if (num > str.length) {
                    clearInterval(this.timer)
                    return
                }
                this.writeText(str.substr(0, num))
                num += 1
            }, 20)
        },
        writeText(t) {
            var text = document.querySelector('.text2');
            text.innerHTML = t;
        },
        //写回答
        writeFun() {
            if (!this.userInfo) {
                PetraLogin.petraFun()
                return
            }
            this.replyState = !this.replyState
            this.contentState = false
        }
    },
};
</script>
  
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
    background: #1E1F26;
    border: none;
    color: #fff;
    padding: 30px;

    &::placeholder {
        color: #40434C;
        font-family: 'Normal';
    }
}

::v-deep .el-input__inner {
    background: #0F1012 !important;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #fff;

    &::placeholder {
        color: #6D6D7A;
        font-family: 'Normal';
    }
}

.baseDetail {
    position: fixed;
    opacity: 1;
    transition: all 0.8s ease 0.1s;
    width: 100%;
    z-index: 96;
    background: #131417;
    left: 0;

    .baseDetail-box {
        height: 100%;
        position: relative;
        overflow-y: auto;

        .baseDetail-header {
            width: 100%;
            height: 62px;
            border-bottom: 1px solid #060606;
            position: sticky;
            top: 0;
            background: #0F1014;
            z-index: 90;

            .baseDetail-header-detail {
                max-width: 1200px;
                margin: 0 auto;
                height: 62px;

                .baseDetail-header-username {
                    font-size: 12px;
                    margin: 0 20px 0 4px;
                }

                .baseDetail-close {
                    width: 24px;
                    height: auto;
                    cursor: pointer;
                }

                .write {
                    height: 34px;
                    padding: 0 18px;
                    background: linear-gradient(142deg, #9AFBE1 0%, #12CC9A 100%);
                    box-shadow: 0px 0px 9px 3px rgba(6, 6, 13, 0.43), inset 1px 1px 4px 0px rgba(229, 253, 255, 0.44);
                    border-radius: 12px;
                    backdrop-filter: blur(29.62025316455696px);
                    margin-right: 50px;
                    cursor: pointer;

                    .write-icon {
                        width: 16px;
                        height: auto;
                    }

                    .write-title {
                        margin-left: 10px;
                        font-size: 14px;
                        color: #222222;
                        line-height: 20px;
                    }
                }
            }
        }

        .baseDetail-detail {
            max-width: 1200px;
            margin: 50px auto 100px auto;

            .baseDetail-title {
                font-size: 20px;
                line-height: 28px;
            }

            .baseDetail-user {
                margin: 20px 0 30px 0;

                .baseDetail-user-img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }

                .baseDetail-user-name {
                    margin-left: 10px;
                    font-size: 14px;
                    line-height: 32px;

                    span {
                        color: #6D6D7A;
                    }
                }
            }

            .baseDetail-brief {
                font-size: 14px;
                color: #DBDCE4;
                line-height: 20px;
            }

            .baseDetail-content {
                white-space: pre-wrap;
                word-break: break-all;
                width: 100%;
                padding: 20px;
                background: #111217;
                border-radius: 10px;
                border: 1px solid #09090B;
                margin-top: 30px;
                font-size: 14px;
                color: #1DE4B3;
                line-height: 20px;
                position: relative;
                transition: all 0.3s;

                .baseDetail-all {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    cursor: pointer;
                }
            }

            .replay-box {
                transition: all 0.3s;
                overflow-y: hidden;
                max-width: 1200px;
                border-radius: 12px;
                margin-top: 30px;

                .editor-box {
                    max-width: 1200px;
                    background: #1E1F26;
                    border: 1px solid #09090B;
                    position: relative;
                    height: 560px;
                    border-radius: 12px;

                    .replay-button {
                        width: 100px;
                        height: 34px;
                        background: #2C2E34 linear-gradient(131deg, #383B43 0%, #252830 100%);
                        border-radius: 12px;
                        text-align: center;
                        line-height: 34px;
                        font-size: 14px;
                        color: #747780;
                        cursor: pointer;
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                        transition: all 0.3s;
                    }

                    .replay-button-active {
                        background: linear-gradient(142deg, #9AFBE1 0%, #12CC9A 100%);
                        box-shadow: inset 1px 1px 4px 0px rgba(229, 253, 255, 0.44);
                        color: #222222;
                    }
                }
            }


            .baseDetail-reply {
                margin: 30px 0 0 0;
                align-items: start;

                .baseDetail-reply-title {
                    flex: 1;
                    font-size: 18px;
                    color: #868CA0;
                    line-height: 25px;
                    margin-top: 7px;
                }

                .baseDetail-reply-ai {
                    width: 123px;
                    height: auto;
                    cursor: pointer;
                }
            }

            .baseDetail-reply-list {
                width: 100%;
                background: #18191F;
                border-radius: 18px;
                border: 1px solid #0F1014;
                padding: 30px;
                margin-bottom: 30px;

                .baseDetail-user-img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }

                .baseDetail-user-name {
                    margin-left: 10px;
                    font-size: 14px;
                    line-height: 32px;
                    flex: 1;

                    span {
                        color: #6D6D7A;
                    }
                }

                .baseDetail-reply-list-icon {
                    width: 17px;
                    height: auto;
                    cursor: pointer;
                }

                .baseDetail-reply-list-reward {
                    font-size: 14px;
                    color: #868CA0;
                    line-height: 20px;
                    margin-left: 2px;
                    cursor: pointer;
                }

                .baseDetail-reply-list-content {
                    // display: -webkit-box;
                    // -webkit-line-clamp: 3;
                    // -webkit-box-orient: vertical;
                    // overflow: hidden;
                    font-size: 14px;
                    color: #DBDCE4;
                    margin-top: 20px;

                    .baseDetail-reply-list-all {
                        float: right;
                        clear: both;
                        color: #1DE4B3;
                        line-height: 12px;
                        cursor: pointer;
                    }
                }

                .baseDetail-reply-list-content::before {
                    content: '';
                    float: right;
                    width: 0;
                    /*设置为0，或者不设置宽度*/
                    height: 50px;
                    /*先随便设置一个高度*/
                }
            }

            .baseDetail-reply-more {
                font-size: 14px;
                color: #6D6D7A;
                cursor: pointer;
                margin-top: 80px;
            }
        }

        .top-title {
            width: 100%;
            height: 88px;
            background: rgba(31, 36, 45, 0.8);
            // opacity: 0.8;
            backdrop-filter: blur(8px);
            justify-content: center;
            position: fixed;
            top: 112px;
            left: 0;

            .top-title-content {
                max-width: 1200px;
                font-size: 20px;
                color: #D7D8E3;
                text-align: center;
            }
        }
    }

    .baseDetail-box::-webkit-scrollbar {
        display: none;
    }
}

.reward-detail {
    width: 450px;
    height: 343px;
    position: fixed;
    z-index: 98;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -225px;
    background-image: url('@/assets/img/common/reward_bg.png');
    background-size: 100% auto;

    .reward-detail-img {
        width: 54px;
        height: 54px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        display: block;
        margin: 27px auto 4px auto;
    }

    .reward-detail-name {
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        position: relative;

        .reward-detail-close {
            width: 10px;
            height: auto;
            position: absolute;
            right: 40px;
            top: -28px;
            cursor: pointer;
        }
    }

    .reward-detail-msg {
        font-size: 14px;
        color: #8C8C9E;
        line-height: 20px;
        text-align: center;
        margin: 4px 0 22px 0;
    }

    .reward-detail-box {
        width: 340px;
        height: 48px;
        background: #0F1012;
        border-radius: 8px;
        margin: 0 auto;

        .reward-detail-input {
            flex: 1;
            margin-left: 20px;


        }

        .reward-detail-unit {
            font-size: 14px;
            margin-right: 20px;
        }
    }

    .reward-detail-footer {
        margin-top: 44px;
        justify-content: center;

        .reward-detail-btn {
            width: 160px;
            height: 42px;
            backdrop-filter: blur(36px);
            font-size: 14px;
            text-align: center;
            line-height: 42px;
            border-radius: 12px;
            cursor: pointer;
            opacity: 0.9;
        }

        .reward-detail-cancel {
            background: #252830;
            margin-right: 20px;
        }

        .reward-detail-confirm {
            background: #1EE9B7;
            color: #222222;
        }
    }
}
</style>
  