<template>
  <div class="home">
    <header-menu v-if="headerState"></header-menu>
    <router-view />
    <footer-menu v-if="footerState"></footer-menu>
    <move-base :show="baseModal.show"></move-base>
  </div>
</template>

<script>
import { mapState } from "vuex";
import headerMenu from "@/components/header.vue";
import footerMenu from "@/components/footer.vue";
import moveBase from "@/views/base/moveBase.vue";
export default {
  name: 'HomeView',
  components: {
    headerMenu, footerMenu, moveBase
  },
  data() {
    return {
      headerState: true,
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(["loginModal", "baseModal"]),
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="scss">
.home {
  width: 100%;
  min-height: 100vh;
  background: #131417;
  color: #fff;
  font-family: 'Normal';
}
</style>
