<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
    <loading-view></loading-view>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import loadingView from "@/components/loading.vue";
import { wLogin } from '@/api/api'
export default {
  name: 'App',
  components: {
    loadingView
  },
  watch: {

  },
  beforeCreate() {
    // localStorage.setItem('token', 'c2b6ebe8b289e9bfdb0b396a01bdc9a91007d344');
  },
  mounted() {
    this.changeAccount()
  },
  methods: {
    ...mapMutations(["CloseBaseModal", "GetUserInfo"]), //登录弹窗
    async changeAccount() {
      let currentAccount = await window.aptos.account();
      // event listener for disconnecting
      window.aptos.onAccountChange((newAccount) => {
        // If the new account has already connected to your app then the newAccount will be returned
        if (newAccount) {
          this.Login(newAccount.address)
          currentAccount = newAccount;
        } else {
          // Otherwise you will need to ask to connect to the new account
          currentAccount = window.aptos.connect();
        }
      });
    },
    Login(address) {
      let data = {
        address: address
      }
      wLogin(data).then(res => {
        localStorage.setItem("auth", JSON.stringify(res.data));
        this.GetUserInfo()
      })
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.CloseBaseModal()
      },
      // 深度观察监听
      deep: true
    }
  },
}
</script>
<style lang="scss">
html,
body,
div,
ul,
li,
input,
button,
img,
textarea,
p,
h1,
a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-family: 'Normal';
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

img {
  font-size: 0;
  line-height: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.cursor {
  cursor: pointer;
}

.text {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.flex {
  display: flex;
  align-items: center;
}

.layer {
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.7;
  backdrop-filter: blur(0px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
}

.Line {
  width: 100%;
  height: 1px;
  border: 1px solid #0D0D0E;
  margin-top: 30px;
}

//字体
.bold {
  font-family: 'Bold';
}

.light {
  font-family: 'Light';
}

.Medium {
  font-family: 'Medium';
}

//颜色
.white {
  color: #fff;
}

//超出两行省略
.text-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 设置最大显示行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.box {
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  background: #131417;
  color: #fff;
  font-family: 'Normal';
}

//列表
.task-list {
  display: flex;
  transition: all 0.3s;

  .task-list-detail {
    width: 224px;
    height: 356px;
    background: #1E1F26;
    border-radius: 8px;
    margin-right: 20px;
    cursor: pointer;
    position: relative;

    .task-list-img-box {
      width: 204px;
      height: 160px;
      border-radius: 8px;
      overflow: hidden;
      margin: 10px;

      .task-list-img {
        display: block;
        transition: all 0.5s ease-out;
        width: 100%;
        height: 100%;
      }

      .task-list-img:hover {
        transform: scale(1.1);
      }
    }


    .task-list-progress {
      flex: 1;
      height: 4px;
      background: #444857;
      border-radius: 4px;
      margin: 0 5px;
    }

    .task-list-title {
      font-size: 16px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      width: 200px;
      margin: 20px auto 10px auto;
      font-family: 'Medium';
    }

    .task-list-user {
      margin: 0 10px;

      .task-list-user-img {
        width: 28px;
        height: 28px;
        border-radius: 5px;
      }

      .task-list-user-name {
        flex: 1;
        font-size: 12px;
        color: #868CA0;
        line-height: 18px;
        margin-left: 7px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: 'Medium';
      }
    }

    .task-list-footer {
      position: absolute;
      bottom: 20px;
      left: 13px;

      .task-list-footer-btn {
        height: 30px;
        padding: 0 10px;
        border-radius: 4px;
        backdrop-filter: blur(29.62025316455696px);
        margin-right: 10px;
        cursor: pointer;

        .task-list-footer-icon {
          width: 14px;
          height: auto;
        }

        .task-list-footer-txt {
          flex: 1;
          font-size: 12px;
          color: #222222;
          margin-left: 4px;
          line-height: 18px;
        }
      }
    }
  }
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.more {
  text-align: center;
  color: #1DE4B3;
  cursor: pointer;
}
</style>
