<template>
  <div>
    <div v-show="dialogVisible" @click="closeFun()" class="layer"></div>
    <div class="depthDetail" :style="{ bottom: dialogVisible ? '0' : '-1000px', height: innerHeight + 'px' }">
      <div class="depthDetail-box">
        <div class="depthDetail-header">
          <div class="depthDetail-header-detail flex">
            <p class="depthDetail-header-username bold white" style="font-size: 16px;">我的任务</p>
            <div style="flex:1;"></div>
            <img @click="closeFun()" class="depthDetail-close" src="@/assets/img/common/common_close4.png" alt="">
          </div>
        </div>
        <div class="myTasks-header">
          <div class="task-nav flex">
            <div class="task-nav-list" v-for="(item, index) in $t('task.mineNavList')" :key="index" @click="navChange(index)"
              style="margin-top: 0;">
              <p class="task-nav-name" :class="{ 'task-nav-active': index == navIndex }">{{ item }}</p>
            </div>
            <div style="flex: 1;"></div>
            <p class="myTasks-num light">数量：<span class="white">10</span></p>
          </div>
        </div>
        <div v-show="navIndex == 0" class="scroll1">
          <div class="task-list taskAll-list">
            <div v-for="(item, index) in 8" :key="index" class="task-list-detail">
              <div class="task-list-img-box">
                <el-image class="task-list-img" :src="require('@/assets/img/demo.jpg')" fit="cover" alt=""></el-image>
              </div>
              <div class="flex" style="margin: 0 5px;">
                <p v-for="(item, index) in 3" :key="index" :style="{ background: index == 0 ? '#1DD1A5' : '' }"
                  class="task-list-progress"></p>
              </div>
              <p class="task-list-title">Move 是一种基于 Rust 的编程语言，它是为 Meta 的 Diem 区块链…</p>
              <div class="task-list-user flex">
                <img class="task-list-user-img" src="@/assets/img/demo.jpg" alt="">
                <p class="task-list-user-name">Eden X官方</p>
              </div>
              <div class="task-list-footer flex">
                <div class="task-list-footer-btn flex"
                  style="background: linear-gradient(90deg, #45E6BF 0%, #1DE4B3 100%);">
                  <img class="task-list-footer-icon" src="@/assets/img/common/APT_icon.png" alt="">
                  <p class="task-list-footer-txt">0.013 APT</p>
                </div>
                <div class="task-list-footer-btn flex" style="background:#444857;">
                  <img class="task-list-footer-icon" src="@/assets/img/common/user_icon.png" alt="">
                  <p class="task-list-footer-txt" style="color: #71747F;">1000{{ $t('common.people') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="navIndex == 1" class="scroll2">
          <div class="task-list taskAll-list">
            <div v-for="(item, index) in 2" :key="index" class="task-list-detail">
              <div class="task-list-img-box">
                <el-image class="task-list-img" :src="require('@/assets/img/demo.jpg')" fit="cover" alt=""></el-image>
              </div>
              <div class="flex" style="margin: 0 5px;">
                <p v-for="(item, index) in 3" :key="index" :style="{ background: index == 0 ? '#1DD1A5' : '' }"
                  class="task-list-progress"></p>
              </div>
              <p class="task-list-title">Move 是一种基于 Rust 的编程语言，它是为 Meta 的 Diem 区块链…</p>
              <div class="task-list-user flex">
                <img class="task-list-user-img" src="@/assets/img/demo.jpg" alt="">
                <p class="task-list-user-name">Eden X官方</p>
              </div>
              <div class="task-list-footer flex">
                <div class="task-list-footer-btn flex"
                  style="background: linear-gradient(90deg, #45E6BF 0%, #1DE4B3 100%);">
                  <img class="task-list-footer-icon" src="@/assets/img/common/APT_icon.png" alt="">
                  <p class="task-list-footer-txt">0.013 APT</p>
                </div>
                <div class="task-list-footer-btn flex" style="background:#444857;">
                  <img class="task-list-footer-icon" src="@/assets/img/common/user_icon.png" alt="">
                  <p class="task-list-footer-txt" style="color: #71747F;">1000{{ $t('common.people') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "myTasks",
  props: {
    "show": { type: Boolean, default: false },
  },
  components: {
  },
  data() {
    return {
      innerHeight: 0,
      dialogVisible: this.show,
      navList: ["进行中的任务", "已完成的任务"],
      navIndex: 0,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        this.dialogVisible = newVal
      },
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight - 50

  },
  methods: {
    closeFun() {
      this.$emit("dialogClose")
    },

    navChange(val) {
      this.navIndex = val
      if (val == 0) {
        let h = document.querySelector('.scroll1').offsetTop + 150;
        document.body.scrollTop = h;
        document.documentElement.scrollTop = h;
      } else {
        let h = document.querySelector('.scroll2').offsetTop + 150;
        document.body.scrollTop = h;
        document.documentElement.scrollTop = h;
      }
    },
  },
};
</script>

<style lang="scss">
.depthDetail {
  position: fixed;
  opacity: 1;
  transition: all 0.8s ease 0.1s;
  width: 100%;
  z-index: 92;
  background: #131417;
  left: 0;

  .depthDetail-box {
    height: 100%;
    position: relative;
    overflow-y: auto;

    .depthDetail-header {
      width: 100%;
      height: 62px;
      border-bottom: 1px solid #060606;
      position: sticky;
      top: 0;
      background: #0F1014;
      z-index: 1;

      .depthDetail-header-detail {
        max-width: 1200px;
        margin: 0 auto;
        height: 62px;

        .depthDetail-header-username {
          font-size: 16px;
          margin: 0 20px 0 4px;
        }

        .depthDetail-close {
          width: 24px;
          height: auto;
          cursor: pointer;
        }
      }
    }
  }

  .depthDetail-box::-webkit-scrollbar {
    display: none;
  }

  .myTasks-header {
    max-width: 1200px;
    margin: 0 auto;
    position: sticky;
    top: 62px;
    background: #131417;
    padding-top: 50px;
      z-index: 1;

    .task-nav {
      padding-top: 30px;
      border-bottom: 1px solid #0D0D0E;
      position: sticky;
      top: 62px;
      background: #131417;
      z-index: 1;

      .task-nav-list {
        position: relative;
        margin-right: 50px;
        cursor: pointer;
        margin-top: 24px;

        .task-nav-name {
          font-size: 16px;
          color: #868CA0;
          height: 42px;
          transition: 0.2s all linear;
          padding-bottom: 20px;
        }

        .task-nav-active {
          color: #fff;
          border-bottom: 2px solid #1EE9B7;
        }

        .task-nav-name::before {
          content: "";
          position: absolute;
          top: -2px;
          left: 100%;
          width: 0;
          height: 100%;
          border-bottom: 2px solid #1EE9B7;
          transition: 0.2s all linear;
        }

        .task-nav-name:hover::before {
          width: 100%;
          top: -2px;
          left: 0;
          transition-delay: 0.1s;
          border-bottom-color: #1EE9B7;
          z-index: 1;
        }

        .task-nav-name:hover~.task-nav-name::before {
          left: 0;
        }

      }
    }
  }

  .taskAll-list {
    max-width: 1200px;
    flex-wrap: wrap;
      margin: 30px auto 100px auto;

    .task-list-detail {
      margin-right: 16px;
      margin-bottom: 30px;
    }
  }
}
</style>
