export default {
  state:"1",
  header:{
    navList: [{name: "Learn To Earn",path: "/task"},{name: "In-depth",path: "/depth"}],
    Publish:'Publish',
    logOut:'Log out',
    msg1:'Are you sure you want to log out?',
    msg2:'Please install the Petra Aptos Wallet for login.',
    msg3:'Install Now',
  },
  task:{
    navList: ["Video Learning", "Reading for Learning"],
    Beginner:'Beginner Learning',
    Advanced:'Advanced Learning',
    Practical:'Practical Learning',
    mineNavList: ["Learning", "Learned"],
    task:'Learn To Earn',
    Level:'Level',
    Start:'Start Learning',
    Learning:'Learning Completed',
    Continue:'Continue Learning',
    Total:'Total Learning',
    Twitter:'Go to Twitter to follow',
    Go:'Go to',
    msg1:'Please paste the address you shared into this input box for verification',
    Paste:'Paste',
    again:'Share again',
    Verify:'Verify now',
    title:'Question-based Learning',
    Back:'Back',
    Continue2:'Continue',
    msg2:'Learning is not yet completed, please finish before moving to the next stage',
    Question:'Quiz.',
    ti:'',
    topic:'Question',
    msg3:'Congratulations, you have answered all the questions correctly. Please continue with your learning.',
  },
  depth:{
    navList: ["Technical Articles ", "Ecology Articles"],
    Participants:'Participants',
    Steps:'Steps',
    Intro:'Intro',
    Time:'Release time',
    Thanks:'Thank you for your recognition and reward.',
    placeholder1:'Enter the reward amount ',
    title1:'Technical Articles',
    title2:'Ecology Articles',
  },
  base:{
    Default:'Default',
    Questions:'Questions',
    Answers:'Answers',
    Comments:'Number of Comments',
    Publish:'Publish',
    Solutions:'Solutions',
    Published:'Published Questions',
    Time:'Participation Time',
    navList: ["Solutions", "Published Questions"],
    Questions2:'Questions',
    Solutions2:'Solutions',
    PublishAnswers:'Publish Answer',
    msg1:'No more replies for now',
  },
  create:{
    placeholder:'Please Enter the Article Title...',
    placeholder2:'Article Summary',
    msg1:'Article Cover Image',
    msg2:'Article Type',
    msg3:'High-resolution images are preferred as article covers.Recommended size: 190mm*132mm. Supported formats: .jpg, .jpeg, .png. Maximum size: 5MB.',
    picture:'Please choose a picture....',
    Edit:'Edit',
  },
  footer:{
    content:'Eden X is the V2 version of Aptos Eden. It aims to assist Web2 users in delving into Move development technology and understanding the Aptos ecosystem deeply. At the same time, it seeks to provide strong support for the development of the Aptos ecosystem.',
  },
  common:{
    lodingMsg:'More',
    noMore:'The end',
    reproducing:'Please indicate the source when reproducing.',
    Cancel:'Cancel',
    Confirm:'Confirm',
    Edit:'Edit',
    Delete:'Delete',
    share:'Share',
    Reward:'Reward',
    viewAll:'Expand to view all',
    Succeeded:'Succeeded',
    placeholder:'Please enter',
    return:'Return',
    all:'All',
    people:'',
    enter:"Please enter the content",
  },
  user:{
    picture:'Change profile picture',
    ArticlesL:'Published Articles',
    Rewards:'Rewards',
    Amount:'Received Reward Amount',
    Nickname:'Edit Nickname',
    Center:'Personal Center',
    msg1:'Are you sure you want to delete the current article?',
    msg2:'Deletion successful.',
    msg3:'Are you sure you want to start learning?',
  }
}