<template>
  <div class="loading">
    <div class="lines">
      <div class="line-col"></div>
      <div class="line-col"></div>
      <div class="line-col"></div>
      <div class="line-col"></div>
      <div class="line-col"></div>
    </div>
  </div>
</template>
<script>

export default {
  name: "loading",
  components: {
  },
  data() {
    return {
    };
  },
  watch: {
    // $route: {
    // },
  },
  mounted() {
    import('../assets/js/scripts.js').then(external => {
      // 外部JavaScript文件加载完成后的逻辑
    });
  },
  methods: {
    fun(){
      $('.lines').removeClass('finish');
			$('.lines').removeClass('ready');
			$('.lines').addClass('no-lines');
    }
  },
};
</script>

<style lang="scss">
$lines_color: rgba(255, 255, 255, 0.07);
$extra_bg: #1E1F24;
$lines_color: rgba(255, 255, 255, 0.07);

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -khtml-border-radius: $radius;
}

@mixin box-shadow($param) {
  box-shadow: $param;
  -moz-box-shadow: $param;
  -webkit-box-shadow: $param;
  -khtml-box-shadow: $param;
}

@mixin transition($param) {
  transition: $param;
  -moz-transition: $param;
  -webkit-transition: $param;
  -o-transition: $param;
}

@mixin transform($param) {
  transform: $param;
  -webkit-transform: $param;
  -moz-transform: $param;
  -o-transform: $param;
}


.lines {
  // background: #131417;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  font-size: 0;
  pointer-events: none;
  z-index: 99;

  &.no-lines {
    .line-col {
      &:before {
        opacity: 0;
      }
    }
  }
}

.lines .line-col {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 20%;
  height: 100vh;
}

.lines .line-col:first-child:before {
  background: transparent;
}

.lines .line-col:first-child:after {
  left: auto;
  right: 0;
  width: 1000%;
}

.lines .line-col:first-child .line {
  display: none;
}

.lines .line-col:last-child:after {
  left: 0;
  right: auto;
  width: 1000%;
}

.lines .line-col:nth-child(1):before {
  -o-transition-delay: 2.9s;
  -webkit-transition-delay: 2.9s;
  transition-delay: 2.9s;
}

.lines .line-col:nth-child(1):after {
  top: 0;
  bottom: auto;
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.lines .line-col:nth-child(2):before {
  -o-transition-delay: 2.7s;
  -webkit-transition-delay: 2.7s;
  transition-delay: 2.7s;
}

.lines .line-col:nth-child(2):after {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.lines .line-col:nth-child(3):before {
  -o-transition-delay: 2.2s;
  -webkit-transition-delay: 2.2s;
  transition-delay: 2.2s;
}

.lines .line-col:nth-child(3):after {
  top: 0;
  bottom: auto;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.lines .line-col:nth-child(4):before {
  -o-transition-delay: 2.5s;
  -webkit-transition-delay: 2.5s;
  transition-delay: 2.5s;
}

.lines .line-col:nth-child(4):after {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.lines .line-col:nth-child(5):before {
  -o-transition-delay: 3s;
  -webkit-transition-delay: 3s;
  transition-delay: 3s;
}

.lines .line-col:nth-child(5):after {
  top: 0;
  bottom: auto;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.lines .line-col:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 0%;
  background: $lines_color;
  @include transition(height 1s cubic-bezier(0.165, 0.85, 0.45, 1) 0s);
}

.lines .line-col:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $extra_bg;
  z-index: 12;
  @include transition(height 1s cubic-bezier(0.165, 0.85, 0.45, 1) 0s);
}

.lines.finish .line-col:before {
  height: 100%;
  background: none;
}

.lines.finish .line-col:after {
  height: 0%;
}

.lines.ready {
  z-index: 11;
}

.wrapper {
  position: relative;
}

.breadcrumbs {
  margin: 0;
}

.lines-off .lines .line-col:before {
  display: none;
}

.lines-on .lines .line-col:before {
  display: block;
}

@media (max-width: 1199px) {
  .lines .line-col {
    width: 33.333%;
  }

  .lines .line-col:nth-child(4),
  .lines .line-col:nth-child(5) {
    display: none;
  }
}

@media (max-width: 720px) {
  .lines .line-col {
    width: 50%;
  }

  .lines .line-col:nth-child(3),
  .lines .line-col:nth-child(4),
  .lines .line-col:nth-child(5) {
    display: none;
  }
}</style>
