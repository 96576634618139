<template>
  <div class="banner">
    <div class="banner-list">
      <ul class="banner-ul">
        <li class="banner-li">
          <div class="banner-li-detail">
            <img class="banner-li-logo" style="width: 72px;" src="@/assets/img/index/cooperate_logo1.png" alt="">
            <p class="banner-li-name">APtos</p>
          </div>
        </li>
        <li class="banner-li">
          <div class="banner-li-detail">
            <img class="banner-li-logo" style="width: 172px;" src="@/assets/img/index/cooperate_logo2.png" alt="">
          </div>
        </li>
        <li class="banner-li">
          <div class="banner-li-detail">
            <img class="banner-li-logo" style="width: 242px;" src="@/assets/img/index/cooperate_logo3.png" alt="">
            <p class="banner-li-name">Chainide</p>
          </div>
        </li>
        <li class="banner-li">
          <div class="banner-li-detail">
            <img class="banner-li-logo" style="width: 72px;margin-top: 54px;" src="@/assets/img/index/cooperate_logo4.png" alt="">
            <p class="banner-li-name">Move developers dao</p>
          </div>
        </li>
        <li class="banner-li">
          <div class="banner-li-detail">
            <img class="banner-li-logo" style="width: 72px;" src="@/assets/img/index/cooperate_logo1.png" alt="">
            <p class="banner-li-name">Aptos</p>
          </div>
        </li>
        <li class="banner-li">
          <div class="banner-li-detail">
            <img class="banner-li-logo" style="width: 172px;" src="@/assets/img/index/cooperate_logo2.png" alt="">
          </div>
        </li>
        <li class="banner-li">
          <div class="banner-li-detail">
            <img class="banner-li-logo" style="width: 242px;" src="@/assets/img/index/cooperate_logo3.png" alt="">
            <p class="banner-li-name">Chainide</p>
          </div>
        </li>
        <li class="banner-li">
          <div class="banner-li-detail">
            <img class="banner-li-logo" style="width: 72px;margin-top: 54px;" src="@/assets/img/index/cooperate_logo4.png" alt="">
            <p class="banner-li-name">Move developers dao</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "banner",
  components: {
  },
  data() {
    return {
    };
  },
  watch: {

  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.banner-list {
  margin: 0 auto;
  width: 100vw;
  overflow-x: hidden;
  height: 260px;
  box-sizing: border-box;
  overflow: hidden;

  .banner-ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 4540px;
    overflow: hidden;
    animation: move 26s linear 0s infinite normal;

    .banner-li {
      float: left;
      margin-right: 128px;
      transition: all 0.3s;
      cursor: pointer;
      .banner-li-detail {
        width: 384px;
        height: 260px;
        background: rgba(255, 255, 255, 0.06);
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(4px);
        .banner-li-logo{
          display: block;
          margin: 66px auto 0 auto;
          height: auto;
        }
        .banner-li-name{
          text-align: center;
          font-size: 24px;
          color: #fff;
          font-family: 'Bold';
          margin-top: 26px;
        }
      }
    }

    .banner-li:hover {
      opacity: 1;
    }

  }

  .banner-ul:hover li {
    opacity: .5;
  }

  .banner-ul:hover {
    -webkit-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
  }
}


@keyframes move {
  from {
    margin-left: 0;
  }

  to {
    margin-left: -2048px;
  }
}
</style>
