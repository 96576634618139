import request from '@/api/index';
export function getMediumList (params) {
    return request({
        url:'/api/v1/kbase/knowledgelist',
        method: 'get',
        params
    })
} 
export function getMediumTopList (params) {
    return request({
        url:'/api/v1/kbase/gettop',
        method: 'get',
        params
    })
} 
export function getKnowledgeDetail (params) {
    return request({
        url:'/api/v1/kbase/knowledgeDetail',
        method: 'get',
        params
    })
}
export function getAnswerDetail (params) {
    return request({
        url:'/api/v1/kanswer/knowledgeanswer',
        method: 'get',
        params
    })
}
export function createQuestion(params) {
    return request({
      url: '/api/v1/knowledge/create',
      method: 'post',
      data: params
    })
}
//个人中戏
export function getUserInfo (params) {
    return request({
        url:'/api/v1/userinfo/getuserinfo',
        method: 'get',
        params
    })
}
export function getSelfKnowList (params) {
    return request({
        url:'/api/v1/userinfo/myselfknowllist',
        method: 'get',
        params
    })
}
export function getSelfAnswerList (params) {
    return request({
        url:'/api/v1/userinfo/myselfknowalist',
        method: 'get',
        params
    })
}
//写回答
export function createAnswer(params) {
    return request({
      url: '/api/v1/kanswer/createanswer',
      method: 'post',
      data: params
    })
}
export function editUserInfo(params) {
    return request({
      url: '/api/v1/user/edituserinfo',
      method: 'post',
      data: params
    })
}
//获取跳转列表
export function getLinksList (params) {
    return request({
        url:'/api/v1/Navigationlinks/linkslist',
        method: 'get',
        params
    })
}
//ai助手
export function getAiList (params) {
    return request({
        url:'/api/v1/aianswer/answerlist',
        method: 'get',
        params
    })
}
//提交问题给ai
export function postQuestion(params) {
    return request({
      url: '/api/v1/aianswer/answer',
      method: 'post',
      data: params
    })
}
export function upErrorAi(params) {
    return request({
      url: '/api/v1/aianswer/uperrorai',
      method: 'post',
      data: params
    })
}