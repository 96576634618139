import request from '@/api/index';

export function getChoiceDepth (params) {
  return request({
    url: '/api/v1/postdepth/getchoicedepth/'+params,
    method: 'get',
  })
}
//获取深度文列表
export function getDepthList (type,page,size) {
    return request({
        url: '/api/v1/postdepth/postdepthlist/' + type + '/' + page + '/' + size,
        method: 'get',
        // data: params
    })
} 
export function getRightDepth (params) {
  return request({
      url: '/api/v1/postdepth/getrightdepth',
      method: 'post',
      data: params
  })
}
//获取深度文详情
export function getDepthDetail (params) {
    return request({
        url: '/api/v1/postdepth/depthdetail',
        method: 'post',
        data: params
    })
}
//获取文章分类
export function getSubcategoryList (params) {
  return request({
      url: '/api/v1/postdepth/getsubcategorylist',
      method: 'get',
      params
  })
}
//发布文章
export function createDepth (params) {
  return request({
      url: '/api/v1/postdepth/create',
      method: 'post',
      data: params
  })
}
//获取用户发布文章列表
export function getUserPostList(page,size) {
  return request({
      url: '/api/v1/postdepth/userpostlist/' + page + '/' + size,
      method: 'get',
  })
}
//用户编辑深度文
export function editDepth (params) {
  return request({
      url: '/api/v1/postdepth/edit',
      method: 'post',
      data: params
  })
}
//删除深度文
export function deleteDepth (params) {
  return request({
      url: '/api/v1/postdepth/delete',
      method: 'post',
      data: params
  })
}
//打赏回调
export function addAward (params) {
  return request({
      url: '/api/v1/useraward/addaward',
      method: 'post',
      data: params
  })
}