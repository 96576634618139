// zn.js
export default {
  state:"0",
  header:{
    navList: [{name: "任务",path: "/task"},{name: "深度文",path: "/depth"}],
    Publish:'发布文章',
    logOut:'退出',
    msg1:'您确定要退出登录？',
    msg2:'请安装Petra Aptos Wallet钱包进行登录',
    msg3:'立即安装',
  },
  task:{
    navList: ["视频学习", "阅读学习"],
    Beginner:'初级学习',
    Advanced:'进阶学习',
    Practical:'实践学习',
    mineNavList: ["进行中的学习", "已完成的学习"],
    task:'任务',
    Level:'级别',
    Start:'开始学习',
    Learning:'学习已完成',
    Continue:'继续学习',
    Total:'学习共计',
    Twitter:'前往Twitter关注',
    Go:'前往',
    msg1:'请把您分享的地址粘贴到此输入框进行验证',
    Paste:'粘贴',
    again:'再次分享',
    Verify:'立即验证',
    title:'答题学习',
    Back:'后退',
    Continue2:'继续',
    msg2:'学习暂未完成，请先完成再继续下一阶段',
    Question:'第',
    ti:'题',
    topic:'题目',
    msg3:'恭喜您，已经答对所有题目，请继续完成任务',

  },
  depth:{
    navList: ["技术文章 ", "生态文章"],
    Participants:'人参与',
    Steps:'步',
    Intro:'简介',
    Time:'发布时间',
    Thanks:'感谢您的认可和奖励。',
    placeholder1:'请输入您要打赏的金额',
    title1:'技术文章',
    title2:'生态文章',
  },
  base:{
    Default:'默认排序',
    Questions:'问题',
    Answers:'回答',
    Comments:'评论数',
    Publish:'发布问题',
    Solutions:'我的解题',
    Published:'我发布的问题',
    Time:'参与时间',
    navList: ["我的解题", "我发布的问题"],
    Questions2:'问答',
    Solutions2:'解决方法',
    PublishAnswers:'发布回答',
    msg1:'暂无更多回复',
  },
  create:{
    placeholder:'请输入文章标题…',
    placeholder2:'文章摘要',
    msg1:'文章封面图',
    msg2:'文章类型',
    msg3:'优质的封面便于推荐，请使用清晰度较高的图片，建议尺寸：190*132图片格式支持.jpg，jpeg，png，图片大小不超过5M。',
    picture:'请选择图片...',
    Edit:'编辑文章',
  },
  footer:{
    content:'Eden X是Aptos Eden的V2版本。旨在帮助Web2用户深入研究Move开发技术，深入理解Aptos生态系统。同时，为Aptos生态系统的发展提供有力支持。'
  },
  common:{
    lodingMsg:'加载更多',
    noMore:'暂无更多',
    reproducing:'转载请注明文章出处',
    Cancel:'取消',
    Confirm:'确认',
    Edit:'编辑',
    Delete:'删除',
    share:'分享',
    Reward:'打赏',
    viewAll:'展开查看全部',
    Succeeded:'登录成功',
    placeholder:'请输入',
    return:'返回',
    all:'全部',
    people:'人',
    enter:"请输入内容...",

  },
  user:{
    picture:'更换头像',
    ArticlesL:'文章',
    Rewards:'打赏次数',
    Amount:'已收到打赏金额',
    Nickname:'修改昵称',
    Center:'个人中心',
    msg1:'您确定要删除当前文章？',
    msg2:'删除成功',
    msg3:'您确定要开始学习吗？',
  }
};