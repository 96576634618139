<template>
    <div>
        <div>
            <div style="border: none;width: 100%;margin: 30px auto 0 auto;">
                <Toolbar :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
                <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
                    @onCreated="onCreated" @customPaste="customPaste" />
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { imageToBase64, base64ToFile } from "../untils/commonApi";
import {
    uploadMore
} from '@/api/api';

export default Vue.extend({
    components: { Editor, Toolbar },
    props: {
        content: {
            type: String
        }
    },
    watch: {
        '$i18n.locale'() {
            location.reload()
        },
        content: {
            handler(newVal) {
                this.html = newVal
            },
        },
    },
    data() {
        return {
            editor: null,
            html: '',
            toolbarConfig: {
                toolbarKeys: [// [ /* 显示哪些菜单，如何排序、分组 */ ]
                    "headerSelect",
                    "blockquote",
                    "header1",
                    "header2",
                    "header3",
                    "|",
                    "bold",
                    "underline",
                    "italic",
                    "through",
                    "color",
                    "bgColor",
                    "clearStyle",
                    "|",
                    "fontSize",
                    "fontFamily",
                    "lineHeight",
                    "|",
                    "bulletedList",
                    "numberedList",
                    // "todo",
                    "justifyLeft",
                    "justifyRight",
                    "justifyCenter",
                    "divider",
                    {
                        iconSvg: "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
                        key: "group-indent",
                        menuKeys: ["indent", "delIndent"]
                    },
                    "|",
                    "insertLink",
                    "emotion",
                    {
                        key: 'group-image',
                        title: '图片',
                        iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
                        menuKeys: ["uploadImage"] //"insertImage", 
                    },
                    // "insertVideo",
                    "insertTable",
                    "codeBlock",
                    "code",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "fullScreen",
                ],
                excludeKeys: [ /* 隐藏哪些菜单 */
                    // 'group-more-style',
                    'blockquote', // 引用
                    // 'code', // ···更多-行内代码
                    // 'clearStyle', // ···更多-清除格式
                    'todo', // 代办
                    // 'emotion', // 表情
                    'insertTable',// 表格
                    // 'codeBlock', // 代码块
                    'group-video', //视频
                    'numberedList',
                    'bulletedList',
                ],
            },



            editorConfig: {
                placeholder: this.$t('common.enter'),
                MENU_CONF: {
                    uploadImage: {
                        server: '/api/v1/image/uploadmore',
                        maxFileSize: 1 * 1024 * 1024, // 1M
                        // base64LimitSize: 1024 * 1024 * 10
                        customUpload: this.uploadImg
                    },
                    insertImage: {
                        onInsertedImage(imageNode) {
                            console.log('插入')
                            console.log(imageNode)

                        }
                    },
                },
            },
            mode: 'default', // or 'simple'
        }
    },

    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        
        },
        //粘贴
        customPaste(editor, event, callback) {
            // console.log('ClipboardEvent 粘贴事件对象', event)
            const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
            // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
            let reg = /<img src=/
            // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）
            if (reg.test(html)) {
                this.disposePasteImg(html).then((res) => {
                    // 将内容追加上
                    editor.dangerouslyInsertHtml(res)
                    event.preventDefault()

                })
            } else {
                editor.dangerouslyInsertHtml(html)
            }

            // console.log(text)
            // 返回 true ，继续默认的粘贴行为
            callback(false)
            // callback(true)
        },
        //上传图片
        uploadImg(file, insertFn) {
            let imgData = new FormData();
            imgData.append('file', file);
            uploadMore(imgData).then(res => {
                if (res.errorCode) {
                    this.$message.error(res.msg);
                    return
                }
                insertFn(res.data.list.url)
            }).catch(() => {
                this.$message.error('查询失败');
            });
            // this.$axios.post(`/api/v1/image/uploadmore`, imgData, { useLog: true }).then((res) => {
            //     if (res.errorCode) {
            //         this.$message.error(res.msg);
            //         return
            //     }
            //     insertFn(res.data.list.url)
            // }).catch(() => {
            //     this.$message.error('上传失败');
            // });
        },
        // 处理粘贴的图片，传递的参数是粘贴来的数据
        disposePasteImg(pasteStr) {
            let _this = this;

            return new Promise(function (resolve) {
                // 用于计数图片数量
                let imgNum = 0;
                //匹配图片
                var imgReg = /<img.*?(?:>|\/>)/gi;
                //匹配src属性
                // eslint-disable-next-line no-useless-escape
                var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;

                // 提取图片连接
                pasteStr.replace(imgReg, function (txt) {
                    return txt.replace(srcReg, function (src) {
                        var img_src = src.match(srcReg)[1];
                        // console.log(img_src);
                        //正则把?x-oss-process后面的都去掉
                        img_src = img_src.replace(/\?.*/i, "");
                        // 查找到一个图片地址就讲图片数量加1
                        imgNum++;
                        // 将图片转成图片文件对象并上传得到图片地址，传递的参数是图片地址
                        _this.imgUrlSwitchBlob2(img_src).then((res) => {
                            // console.log("得到的地址res", res);
                            /**
                             * 得到图片地址进行替换并将替换之后的文本返回渲染
                             */
                            // 图片地址进行替换
                            pasteStr = pasteStr.replace(img_src, res);
                            // 替换之后将图片数量减1
                            imgNum--;
                            // 只有图片数量变成0的时候才会进行返回
                            if (imgNum == 0) {
                                // console.log("返回imgNum", imgNum,pasteStr)
                                resolve(pasteStr);
                            }
                        });
                    });
                }
                );
            });
        },
        //图片转文件
        imgUrlSwitchBlob2(param) {
            let that = this;
            return new Promise(function (resolve) {
                // 得到图片后缀
                var image = new Image();
                image.crossOrigin = '';
                image.src = param;
                image.onload = function () {
                    let base64 = imageToBase64(image); //图片转base64
                    // console.log(base64)
                    // return
                    var ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
                    let file = base64ToFile(base64, 'file.' + ext); //base64转File
                    // 根据自身需求调整【因个人项目逻辑不一样，这里使用回调函数】
                    that.upPasteImg(file).then((res) => {
                        resolve(res);
                    });
                }
            });
        },
        //上传图片
        upPasteImg(file) {
            let _this = this;
            // 更改成Promise函数
            return new Promise(function (resolve) {
                let imgData = new FormData();
                imgData.append('file', file);
                // _this.$axios.post(`/api/v1/image/uploadmore`, imgData, { useLog: true }).then((res) => {
                //     if (res.errorCode) {
                //         _this.$message.error(res.msg);
                //         return
                //     }
                //     resolve(res.data.list.url)
                // }).catch(() => {
                //     _this.$message.error('上传失败');
                // });
                uploadMore(imgData).then(res => {
                    if (res.errorCode) {
                        _this.$message.error(res.msg);
                        return
                    }
                    resolve(res.data.list.url)
                }).catch(() => {
                    this.$message.error('查询失败');
                });
            });
        },
    },
    mounted() {
        // 模拟 ajax 请求，异步渲染编辑器
        setTimeout(() => {
            this.html = this.content
        }, 1000)

    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss"></style>
<style lang="scss">
.w-e-toolbar,
.w-e-text-container {
    background: #131417;
    color: #fff;
}

.w-e-bar-item {
    color: #6D6D7A;
}

.w-e-bar-divider {
    background-color: #6D6D7A;
}
</style>