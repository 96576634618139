import request from '@/api/index';

//查询banner
export function getBannerlist() {
    return request({
        url: '/api/v1/banner/bannerlist',
        method: 'get',
    });
}
export function uploadMore(data) {
    return request({
        url: '/api/v1/image/uploadmore',
        method: 'post',
        data
    });
}
//登录
export function wLogin(data) {
    return request({
        url: '/api/v1/user/wlogin',
        method: 'post',
        data
    });
}
//查看用户信息
export function getUserinfo() {
    return request({
        url: '/api/v1/userinfo/getuserinfo',
        method: 'get',
    });
}
//修改用户信息
export function editUserinfo(data) {
    return request({
        url: '/api/v1/user/edituserinfo',
        method: 'post',
        data
    });
}
//修改用户头像
export function editUserpic(data) {
    return request({
        url: '/api/v1/user/edituserpic',
        method: 'post',
        data
    });
}