import Vue from 'vue'
import VueRouter from 'vue-router'
import indexView from '../views/index.vue'
import Home from '@/views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: indexView,
  },
  {
    path: '/home',
    component: Home,
    children: [
      //任务
      {
        path: '/task',
        name: 'task',
        component: () => import(/* webpackChunkName: "about" */ '../views/task/HomeTask.vue')
      },
      {
        path: '/task/allList',
        name: 'allList',
        component: () => import(/* webpackChunkName: "about" */ '../views/task/taskAllList.vue')
      },
      //深度文
      {
        path: '/depth',
        name: 'depth',
        component: () => import(/* webpackChunkName: "about" */ '../views/depth/HomeDepth.vue'),
        // meta: {
        //   headerIsShow: true,
        //   footerIsShow: true,
        // }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
