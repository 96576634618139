<template>
  <div class="mine-base">
    <div class="mine-base-header">
      <div class="mine-base-user">
        <img class="mine-base-user-photo" :src="userInfo.userpic" alt="">
        <div class="mine-base-header-detail">
          <p class="mine-base-header-userName bold white">{{ userInfo.username ? userInfo.username : '' | nameFilter }}</p>
          <div class="flex">
            <p class="mine-base-header-name light">{{$t('base.Solutions')}}：</p>
            <p class="mine-base-header-num bold white">{{AnswerTotal}}</p>
            <p class="mine-base-header-line"></p>
            <p class="mine-base-header-name light">{{$t('base.Published')}}：</p>
            <p class="mine-base-header-num bold white">{{SelfKnowTotal}}</p>
            <!-- <p class="mine-base-header-line"></p>
            <p class="mine-base-header-name light">我的提问：</p>
            <p class="mine-base-header-num bold white">192</p>
            <p class="mine-base-header-line"></p>
            <p class="mine-base-header-name light">我的回答：</p>
            <p class="mine-base-header-num bold white">192</p> -->
          </div>
        </div>
      </div>
      <div class="header-nav flex">
        <div class="header-nav-list" v-for="(item, index) in $t('base.navList')" :key="index" @click="navChange(index)"
          style="margin-top: 0;">
          <p class="header-nav-name light" :class="{ 'header-nav-active': index == navIndex }">{{ item }}</p>
        </div>
        <p class="header-nav-num">数量：<span>{{ navIndex == 0? AnswerTotal:SelfKnowTotal}}</span></p>
      </div>
      <p class="Line" style="margin-top: 0;"></p>
    </div>
    <div v-show="navIndex == 0">
      <div v-for="(item, index) in SelfKnowList" :key="index" class="mine-detail" @click="detailFun(item)">
        <div class="base-list flex">
          <div class="base-list-detail">
            <p class="base-list-title">
              {{ item.title_en }}
            </p>
            <div class="flex">
              <p class="base-list-state light">发布时间：{{ item.create_time }}</p>
            </div>
          </div>
          <div class="base-list-icon"></div>
        </div>
        <p class="Line"></p>
      </div>
      <p class="more" :style="{ color: listQuery.scroll ? '#1DE4B3' : '#6D6D7A', marginTop: '30px' }">
        {{ listQuery.scroll ? $t('common.lodingMsg') : $t('common.noMore') }}
      </p>
    </div>
    <div v-show="navIndex == 1">
      <div v-for="(item, index) in AnswerList" :key="index" class="mine-detail" @click="detailFun(item)">
        <div class="base-list flex">
          <div class="base-list-detail">
            <p class="base-list-title">
              {{ item.title_en }}
            </p>
            <div class="flex">
              <p class="base-list-state light">回答时间：{{ item.create_time }}</p>
            </div>
          </div>
          <div class="base-list-icon"></div>
        </div>
        <div class="answer">
          <p class="answer-title">我的回答：</p>
          <p class="answer-content light">
            {{ item.content }}
          </p>
        </div>
        <p class="Line"></p>
      </div>
      <p class="more" :style="{ color: listQuery.scroll ? '#1DE4B3' : '#6D6D7A', marginTop: '30px' }">
        {{ listQuery.scroll ? $t('common.lodingMsg') : $t('common.noMore') }}
      </p>
    </div>
  </div>
</template>

<script>
import { getSelfAnswerList, getSelfKnowList } from '@/api/medium'
export default {
  name: 'HomeView',
  filters: {
    nameFilter(name) {
      if (name.length > 10) {
        let a = name.slice(0, 4)
        let b = name.slice(62, 66)
        return a + '****' + b;
      } else {
        return name;
      }
    },
  },
  components: {
  },
  data() {
    return {
      navList: ["我的解题", "我发布的问题"],
      navIndex: 0,
      userInfo: {},
      listQuery: {
        page: 1,
        size: 10,
        scroll: true,
      },
      listQuery2: {
        page: 1,
        size: 10,
        scroll: true,
      },
      SelfKnowList: [],//我发布的问题
      SelfKnowTotal:0,
      listQuery4: {
        page: 1,
        size: 10,
        scroll: true,
      },
      AnswerList: [],//我的回答
      AnswerTotal:0,
    };
  },
  computed: {
  },
  mounted() {
    this.userInfo = this.$store.state.userInfo
    this.getAnswerList()
    this.getQuestionList()
  },
  methods: {
    navChange(val) {
      this.navIndex = val
    },
    //查看详情
    detailFun(val) {
      this.$emit("detail", val.id);
    },
    //我发布的问题
    getQuestionList() {
      if (!this.listQuery2.scroll) return
      let data = {
        page: this.listQuery2.page,
        pageSize: this.listQuery2.size,
      }
      getSelfKnowList(data).then(res => {
        if (this.listQuery2.page == 1) {
          this.SelfKnowList = res.data.data
        } else {
          this.SelfKnowList = [...this.SelfKnowList, ...res.data.data]
        }
        if (res.data.data.length < this.listQuery2.size) {
          this.listQuery2.scroll = false
        } else {
          this.listQuery2.page += 1
        }
        this.SelfKnowTotal = res.data.total
      })
    },
    //我的回答
    getAnswerList() {
      if (!this.listQuery4.scroll) return
      let data = {
        page: this.listQuery4.page,
        pageSize: this.listQuery4.size,
      }
      getSelfAnswerList(data).then(res => {
        if (this.listQuery4.scroll == 1) {
          this.AnswerList = res.data.data
        } else {
          this.AnswerList = [...this.AnswerList, ...res.data.data]
        }
        if (res.data.data.length < this.listQuery4.size) {
          this.listQuery4.scroll = false
        } else {
          this.listQuery4.page += 1
        }
        this.AnswerTotal = res.data.total
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.mine-base {
  max-width: 1200px;
  margin: 0 auto;

  .mine-base-header {
    padding-top: 50px;
    position: sticky;
    top: 72px;
    background: #131417;

    .mine-base-user {
      display: flex;

      .mine-base-user-photo {
        width: 100px;
        height: 100px;
        border-radius: 13px;
      }

      .mine-base-header-detail {
        margin-left: 20px;

        .mine-base-header-userName {
          font-size: 24px;
          line-height: 34px;
          margin: 24px 0 20px 0;
        }

        .mine-base-header-name {
          font-size: 14px;
          color: #868CA0;
          line-height: 20px;
        }

        .mine-base-header-num {
          font-size: 16px;
        }

        .mine-base-header-line {
          width: 1px;
          height: 12px;
          backdrop-filter: #868CA0;
          margin: 0 10px;
        }
      }
    }

    .header-nav {
      margin-top: 50px;

      .header-nav-list {
        position: relative;
        margin-right: 50px;
        cursor: pointer;
        margin-top: 24px;

        .header-nav-name {
          font-size: 16px;
          color: #868CA0;
          height: 42px;
          transition: 0.2s all linear;
          padding-bottom: 20px;
        }

        .header-nav-active {
          color: #fff;
          border-bottom: 2px solid #1EE9B7;
        }

        .header-nav-name::before {
          content: "";
          position: absolute;
          top: -2px;
          left: 100%;
          width: 0;
          height: 100%;
          border-bottom: 2px solid #1EE9B7;
          transition: 0.2s all linear;
        }

        .header-nav-name:hover::before {
          width: 100%;
          top: -2px;
          left: 0;
          transition-delay: 0.1s;
          border-bottom-color: #1EE9B7;
          z-index: 1;
        }

        .header-nav-name:hover~.header-nav-name::before {
          left: 0;
        }

      }

      .header-nav-num {
        color: #868CA0;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 20px;
        flex: 1;
        text-align: right;

        span {
          color: #fff;
        }
      }
    }
  }

  .mine-detail {
    .base-list {
      margin-top: 30px;
      cursor: pointer;

      .base-list-icon {
        width: 16px;
        height: 16px;
        background-image: url('@/assets/img/medium/next.png');
        background-size: 100% auto;
        transition: all 0.3s;
      }

      .base-list-detail {
        flex: 1;
        margin-right: 30px;

        .base-list-title {
          font-size: 16px;
          color: #D7D8E3;
          line-height: 22px;
          margin-bottom: 20px;
          transition: all 0.3s;
        }

        .base-list-state {
          font-size: 16px;
          color: #6D6D7A;
          margin-right: 30px;
        }
      }
    }

    .base-list:hover .base-list-title {
      color: #1DE4B3;
    }

    .base-list:hover .base-list-icon {
      background-image: url('@/assets/img/medium/next2.png');
    }

    .answer {
      max-width: 1200px;
      background: #18191F;
      border-radius: 10px;
      border: 1px solid #0F1014;
      padding: 20px;
      margin-top: 20px;

      .answer-title {
        font-size: 14px;
        color: #656B7D;
        line-height: 20px;
      }

      .answer-content {
        font-size: 14px;
        color: #A5A6B8;
        line-height: 20px;
        margin-top: 10px;
      }
    }
  }
}
</style>
