import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //登录弹框
    loginModal: {
      show: false
    },
    //知识库
    baseModal: {
      show: false
    },
    userInfo: '',
  },
  getters: {
  },
  mutations: {
    ShowLoginModal(state) {
      state.loginModal.show = true;
    },
    CloseLoginModal(state) {
      state.loginModal.show = false;
    },
    ShowBaseModal(state){
			state.baseModal.show = true;
		},
		CloseBaseModal(state){
			state.baseModal.show = false;
		},
    //清空用户信息
		ClearUserInfo(state){
			localStorage.removeItem('auth');
			state.userInfo = '';
		},
    GetUserInfo(state){
			if(localStorage.getItem('auth')){
				state.userInfo = JSON.parse(localStorage.getItem('auth'));
			}else{
				state.userInfo = JSON.parse(localStorage.getItem('auth'));
			}
		},
  },
  actions: {
  },
  modules: {
  }
})
