<template>
  <div class="header">
    <div class="header-base">
      <img class="header-ai" src="@/assets/img/home/ai_img.png" alt="">
      <p style="flex: 1;"></p>
      <!-- <div class="header-search flex">
        <img class="header-search-icon" src="@/assets/img/common/search_icon.png" alt="">
        <el-input class="header-search-box" v-model="search" placeholder="您想要找点什么吗？"></el-input>
      </div> -->
      <img v-if="userInfo" @click="userEden()" class="header-base-photo" :src="userInfo.userpic" alt="">
      <p v-if="userInfo" @click="userEden()" class="header-base-username light white">{{ userInfo.username ? userInfo.username : '' | nameFilter }}</p>
      <!-- <img @click="newsState = !newsState" class="header-base-news" src="@/assets/img/medium/news.png" alt=""> -->
      <img @click="CloseFun()" class="header-base-close" src="@/assets/img/common/common_close4.png" alt="">

      <!-- 知识库消息 -->
      <div>
        <div class="base-news" :style="{height:newsState?'600px':'0px',width:newsState?'360px':'0px'}">
          <div class="base-news-detail">
            <p class="base-news-title">知识库消息</p>
            <div v-for="(item, index) in 6" :key="index" class="base-news-list">
              <p class="base-news-detail light">Bruno Lopes
                <span style="color: #95999C;">回复了您的提问</span>
                <span style="color: #1DE4B3;">Solution Public class Solution </span>
              </p>
              <p class="base-news-time"> 22:32:44</p>
              <p class="base-news-circle"></p>
            </div>
          </div>
        </div>
        <div class="base-news-layer" :style="{height:newsState?'600px':'0px',width:newsState?'366px':'0px'}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  filters: {
    nameFilter(name) {
      if (name.length > 10) {
        let a = name.slice(0, 4)
        let b = name.slice(62, 66)
        return a + '****' + b;
      } else {
        return name;
      }

    },
  },
  name: 'header-pc',
  components: {
  },
  props: {
    "stateType": { type: Number, default: 0 }
  },
  data() {
    return {
      search: '',
      newsState:false,
      stateUser:this.stateType,
      userInfo: {},
    };
  },
  watch: {
    stateType: {
      handler(newVal) {
        this.stateUser = newVal
      },
    },
    isUserInfo: {
      handler(nval) {
        this.userInfo = nval
        if (this.$store.state.userInfo) {
        this.username = this.userInfo.username
        }
      }
    }
  },
  computed: {
    isUserInfo() {
      if (this.$store.state.userInfo) {
        this.userInfo = this.$store.state.userInfo
        this.username = this.userInfo.username
      }
      return this.$store.state.userInfo
    }
  },
  mounted() {
    this.navIndex = this.$route.fullPath
  },
  methods: {
    ...mapMutations(["CloseBaseModal","GetUserInfo"]), //登录弹窗
    userEden(){
      this.$emit("user")
    },
    CloseFun(){
      if(this.stateUser == 1){
        this.$emit("eden")
      }else{
        this.CloseBaseModal()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 72px;
  background: #0F1014;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 89;
  border-bottom: 1px solid #060606;

  .header-base {
    width: 100%;
    max-width: 1200px;
    height: 72px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    position: relative;

    .header-ai {
      width: 224px;
      height: auto;
      cursor: pointer;
    }

    .header-search {
      width: 230px;
      height: 40px;
      background: #252830;
      border-radius: 6px;
      margin-left: 50px;

      .header-search-icon {
        width: 16px;
        height: auto;
        margin-left: 20px;
      }


    }

    .header-base-photo {
      width: 32px;
      height: 32px;
      margin: 0 8px 0 32px;
      border-radius: 5px;
      cursor: pointer;
    }

    .header-base-username {
      font-size: 16px;
      color: #DBDCE4;
      cursor: pointer;
      margin-right: 32px;
    }

    .header-base-news {
      width: 24px;
      height: auto;
      cursor: pointer;
    }

    .header-base-close {
      width: 24px;
      height: auto;
      margin-left: 32px;
      cursor: pointer;
    }

    .base-news {
      width: 360px;
      height: 600px;
      background: #131417;
      border-radius: 8px;
      border: 1px solid #070707;
      position: absolute;
      right: -106px;
      top: 82px;
      z-index: 1;
      transition: all 0.6s;

      .base-news-detail {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: relative;

        .base-news-title {
          padding: 20px;
          font-size: 16px;
          line-height: 22px;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 1;
          background: #131417;
        }

        .base-news-list {
          width: 320px;
          margin: 0 auto 20px auto;
          background: #0F1014;
          border-radius: 6px;
          border: 1px solid #060606;
          cursor: pointer;
          position: relative;
          padding: 10px;
          transition: all 0.3s;

          .base-news-detail {
            font-size: 14px;
            color: #DBDCE4;
            line-height: 20px;
          }

          .base-news-time {
            font-size: 12px;
            color: #6D6D7A;
            line-height: 18px;
            margin-top: 8px;
          }

          .base-news-circle {
            width: 6px;
            height: 6px;
            background: #F01756;
            position: absolute;
            top: 6px;
            right: 6px;
            border-radius: 3px;
          }
        }

        .base-news-list:hover {
          background: #1E2029;
        }
      }
    }

    .base-news-detail::-webkit-scrollbar {
      display: none;
    }

    .base-news-layer {
      width: 366px;
      height: 600px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      filter: blur(0px);
      position: absolute;
      right: -116px;
      top: 92px;
      transition: all 0.6s;
    }
  }
}
</style>
<style lang="scss">
.header-search-box {
  flex: 1;
  margin-left: 10px;
  background: #252830;

  .el-input__inner {
    background: #252830 !important;
    border: none;
    color: #fff;
    padding: 0;
  }
}
</style>
