<template>
  <div class="footer">
    <div class="footer-detail flex" style="align-items: start;">
      <div class="footer-l">
        <img class="footer-l-logo" src="@/assets/img/index/intro_logo.png" alt="">
        <p class="footer-l-content white light">
          {{$t('footer.content')}}
        </p>
      </div>
      <div class="footer-r">
        <p class="footer-r-title bold white">Contact Us</p>
        <div class="footer-r-detail flex">
          <!-- <div class="footer-r-content">
            <p class="footer-r-name Medium">Email</p>
            <p class="footer-r-txt">suibiandade@163.com</p>
          </div> -->
          <div class="footer-r-content">
            <p class="footer-r-name Medium">Discord</p>
            <p class="footer-r-txt">https://discord.gg/R5E3sUCrTC</p>
          </div>
          <div class="footer-r-content">
            <a href="https://discord.gg/R5E3sUCrTC" target="_blank">
              <p class="footer-r-name Medium">Twitter</p>
            </a>
            <a href="https://twitter.com/AptosEden" target="_blank">
            <p class="footer-r-txt">https://twitter.com/AptosEden@sid</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "footer-pc",
  components: {
  },
  data() {
    return {
    };
  },
  watch: {
    // $route: {
    // },
  },
  mounted() {

  },
  methods: {
  },
};
</script>

<style lang="scss">
.footer {
  width: 100%;
  height: 280px;
  background: #0F1013;
  padding-top: 50px;

  .footer-detail {
    max-width: 1200px;
    margin: 0 auto;

    .footer-l {
      width: 600px;
      margin-right: 150px;

      .footer-l-logo {
        width: 152px;
        height: auto;
        display: block;
        margin-bottom: 30px;
      }
      .footer-l-content{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }
    }
    .footer-r{
      flex: 1;
      .footer-r-title{
        font-size: 28px;
        line-height: 44px;
      }
      .footer-r-detail{
        margin-top: 30px;
        .footer-r-content{
          margin-right: 60px;
          cursor: pointer;
          .footer-r-name{
            font-size: 18px;
            color: #989898;
            line-height: 24px;
          }
          .footer-r-txt{
            font-size: 14px;
            line-height: 20px;
            margin-top: 16px;
            color: #8E8F90;
            transition: all 0.3s;
          }
          .footer-r-txt:hover{
            color: #1AB891;
          }
        }
      }
    }
  }
}</style>
