<template>
  <div>
    <div v-show="dialogVisible" @click="closeFun()" class="layer"></div>
    <div class="depthDetail" :style="{ bottom: dialogVisible ? '0' : '-1000px', height: innerHeight + 'px' }">
      <div class="depthDetail-box">
        <div class="depthDetail-header">
          <div class="depthDetail-header-detail flex">
            <p class="depthDetail-header-username bold white" style="font-size:16px;">{{ $t('user.Center') }}</p>
            <div style="flex:1;"></div>
            <img @click="closeFun()" class="depthDetail-close" src="@/assets/img/common/common_close4.png" alt="">
          </div>
        </div>
        <div class="user-header flex">
          <img class="user-header-img" :src="userInfo.userpic" alt="" />
          <div class="user-header-detail">
            <div class="flex">
              <el-upload class="create-upload flex" action="/api/v1/image/uploadmore" :headers="headers"
                :on-success="uploadSuccess" accept=".jpg,.jpeg,.png">
                <p class="user-header-upload white">{{ $t('user.picture') }}</p>
              </el-upload>
            </div>
            <div class="user-header-name-box flex">
              <p class="user-header-name bold white">{{ userInfo.username ? userInfo.username : '' | nameFilter }}</p>
              <!-- <img class="user-header-edit" src="@/assets/img/common/edit_icon.png" alt=""> -->
              <p class="user-header-editName" @click="editName = true">{{ $t('user.Nickname') }}</p>
              <p style="flex: 1;"></p>
              <!-- <div class="user-header-search flex">
                <img class="user-header-search-icon" src="@/assets/img/common/search_icon.png" alt="">
                <el-input class="user-header-search-box" v-model="userSearch" placeholder="搜索文章"></el-input>
              </div> -->
            </div>
            <div class="flex">
              <p class="user-header-list light">{{ $t('user.ArticlesL') }}</p>
              <p class="user-header-num white bold">{{ userDetail.user_post_count }}</p>
              <p class="user-header-list light">{{ $t('user.Rewards') }}</p>
              <p class="user-header-num white bold">{{ userDetail.user_award_count }}</p>
              <p class="user-header-list light">{{ $t('user.Amount') }}</p>
              <p class="user-header-num white bold" style="color: #1DE4B3;">{{ userDetail.award }} APT</p>
              <!-- <p class="user-header-history light">历史打赏记录</p> -->
            </div>
          </div>
        </div>
        <div class="userCenter">
          <div v-for="(item, index) in postList" :key="index">
            <div class="depth-list flex">
              <div class="depth-list-detail">
                <p class="depth-list-title bold white">{{ item.title }}</p>
                <p class="depth-list-content light white text-overflow">
                  {{ item.desc }}
                </p>
                <div class="depth-list-user flex">
                  <p class="depth-list-time light">{{ $t('depth.Time') }}：{{ item.create_time }}</p>
                  <p @click="editFun(item)" class="depth-list-btn light" style="color: #1DE4B3;">{{ $t('common.Edit') }}</p>
                  <p @click="delFun(item)" class="depth-list-btn light" style="color: #FF256D;">{{ $t('common.Delete') }}
                  </p>
                </div>
              </div>
              <el-image class="depth-list-img" :src="item.titlepic" fit="cover" alt=""></el-image>
            </div>
            <p class="Line" style="margin-top: 26px;margin-bottom: 50px;"></p>
          </div>
          <p class="more" @click="getList(listQuery.page, listQuery.size)"
            :style="{ color: listQuery.scroll ? '#1DE4B3' : '#6D6D7A' }">
            {{ listQuery.scroll ? $t('common.lodingMsg') : $t('common.noMore') }}
          </p>
        </div>
      </div>
    </div>
    <!-- 修改昵称 -->
    <div v-show="editName" class="layer" style="z-index: 93;"></div>
    <transition name="el-zoom-in-top">
      <div v-show="editName" class="editUsername">
        <p class="editUsername-title white bold">{{ $t('user.Nickname') }}</p>
        <el-input class="editUsername-input" v-model="username" placeholder=""></el-input>
        <div class="flex editUsername-footer">
          <p class="editUsername-btn" style="background: #252830;" @click="editName = false">{{ $t('common.Cancel') }}</p>
          <p @click="editUserinfo" class="editUsername-btn" style="background: #1EE9B7;color: #222222;margin-left: 20px;">
            {{ $t('common.Confirm') }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import editor from "@/components/editor.vue"
import { getUserPostList, deleteDepth } from '@/api/depth'
import { getUserinfo, editUserinfo, editUserpic } from '@/api/api'
import createDepth from "@/views/user/createDepth.vue";
export default {
  name: "userCenter",
  filters: {
    nameFilter(name) {
      if (name.length > 10) {
        let a = name.slice(0, 4)
        let b = name.slice(62, 66)
        return a + '****' + b;
      } else {
        return name;
      }
    },
  },
  props: {
    "show": { type: Boolean, default: false },
  },
  components: {
    editor, createDepth, deleteDepth
  },
  data() {
    return {
      headers: {
        token: JSON.parse(localStorage.getItem('auth')).token,
        lg: localStorage.getItem('languageStorage')
      },
      innerHeight: 0,
      dialogVisible: this.show,
      title: '', //标题
      abstract: '', //摘要
      html: '', //内容
      fileurl: '',
      imgId: '',
      typeList: ["技术文章", "生态文章"],
      typeIndex: 0,
      userSearch: '',
      listQuery: {
        page: 1,
        size: 10,
        scroll: true,
      },
      postList: [],
      userInfo: {},
      userDetail: {},
      userPostNum: 0,
      username: '',//编辑用户名
      editName: false,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        this.dialogVisible = newVal
        if (newVal) {
          this.postList = []
          this.listQuery = {
            page: 1,
            size: 10,
            scroll: true,
          }
          this.getList(this.listQuery.page, this.listQuery.size)
          this.getUserinfo()
        }
      },
    },
    isUserInfo: {
      handler(nval) {
        this.userInfo = nval
        if (this.$store.state.userInfo) {
          this.username = this.userInfo.username
        }
      }
    }
  },
  mounted() {
    this.innerHeight = window.innerHeight - 50
  },
  computed: {
    isUserInfo() {
      if (this.$store.state.userInfo) {
        this.userInfo = this.$store.state.userInfo
        this.username = this.userInfo.username
      }
      return this.$store.state.userInfo
    }
  },
  methods: {
    ...mapMutations(["GetUserInfo"]), //登录弹窗
    closeFun() {
      this.$emit("dialogClose")
    },
    //验证字符长度
    changeValue(attr, num, type) {
      //1-标题 2-摘要
      let value = attr; //校验的字段
      // 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
      let maxLimitStr = num; //25个汉字
      let pass = true;
      let substringStr = "";
      let length = 0;
      let strArr = value.split("");
      strArr.map((str) => {
        if (pass) {
          if (cnReg.test(str)) {
            //中文
            length++;
          } else {
            //英文
            length += 0.5;
          }
          //大于最大限制的时候
          if (length > maxLimitStr) {
            pass = false;
            if (type == 1) {
              this.title = substringStr;
            } else if (type == 2) {
              this.abstract = substringStr
            }
          } else {
            substringStr += str;
          }
        }
      });
      return pass;
    },
    //上传头像
    uploadSuccess(res, file, fileList) {
      this.file = file;
      this.fileurl = res.data.list.url
      this.imgId = res.data.list.id
      this.editUserpic(res.data.list.url)
    },
    //查询用户文章列表
    getList(page, size) {
      if (!this.listQuery.scroll) return
      getUserPostList(page, size).then(res => {
        this.postList = this.postList.concat(res.data.data)
        this.userPostNum = res.data.total
        if (res.data.data.length < size) {
          this.listQuery.scroll = false
        } else {
          this.listQuery.page += 1
          this.listQuery.scroll = true
        }
      })
    },
    //编辑深度文
    editFun(val) {
      this.$emit("dialogClose")
      this.$emit("edit", val.post_token)

    },
    //删除深度文
    delFun(val) {
      this.$confirm(this.$t('user.msg1'), {
        confirmButtonText: this.$t('common.Confirm'),
        cancelButtonText: this.$t('common.Cancel')
      })
        .then(_ => {
          let data = {
            post_id: val.post_id
          }
          deleteDepth(data).then(res => {
            this.$message({
              message: this.$t('user.msg2'),
              type: 'success'
            })
            this.postList = []
            this.listQuery = {
              page: 1,
              size: 10,
              scroll: true,
            }
            this.getList(this.listQuery.page, this.listQuery.size)
          })
        })
        .catch(_ => { });
    },
    getUserinfo() {
      getUserinfo().then(res => {
        this.userDetail = res.data
        let auth = JSON.parse(localStorage.getItem('auth'))
        auth.username = res.data.username
        auth.userpic = res.data.userpic
        localStorage.setItem("auth", JSON.stringify(auth));
        this.GetUserInfo()
      })
    },
    editUserinfo() {
      let data = {
        name: this.username
      }
      editUserinfo(data).then(res => {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.editName = false
        this.getUserinfo()
      })
    },
    //修改用户头像
    editUserpic(userpic) {
      let data = {
        userpic: userpic
      }
      editUserpic(data).then(res => {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.getUserinfo()
      })
    }
  },
};
</script>

<style lang="scss">
.depthDetail {
  position: fixed;
  opacity: 1;
  transition: all 0.8s ease 0.1s;
  width: 100%;
  z-index: 92;
  background: #131417;
  left: 0;

  .depthDetail-box {
    height: 100%;
    position: relative;
    overflow-y: auto;

    .depthDetail-header {
      width: 100%;
      height: 62px;
      border-bottom: 1px solid #060606;
      position: sticky;
      top: 0;
      background: #0F1014;
      z-index: 1;

      .depthDetail-header-detail {
        max-width: 1200px;
        margin: 0 auto;
        height: 62px;

        .depthDetail-header-username {
          font-size: 16px;
          margin: 0 20px 0 4px;
        }

        .create-btn {
          height: 28px;
          padding: 0 10px;
          cursor: pointer;
          background: linear-gradient(90deg, #45E6BF 0%, #1DE4B3 100%);
          border-radius: 4px;
          margin-right: 50px;

          .create-btn-icon {
            width: 16px;
            height: auto;
          }

          .create-btn-name {
            font-size: 12px;
            color: #222222;
            margin-left: 4px;
          }
        }

        .depthDetail-close {
          width: 24px;
          height: auto;
          cursor: pointer;
        }
      }
    }

    .user-header {
      width: 100%;
      background: #131417;
      position: sticky;
      top: 62px;
      z-index: 1;
      padding-top: 50px;
      border-bottom: 1px solid #0D0D0E;
      padding-bottom: 30px;
      max-width: 1200px;
      margin: 0 auto;

      .user-header-img {
        width: 128px;
        height: 128px;
        border-radius: 16px;
      }

      .user-header-detail {
        flex: 1;
        margin-left: 20px;

        .el-upload-list {
          display: none !important;
        }

        .user-header-upload {
          height: 30px;
          padding: 0 16px;
          background: linear-gradient(131deg, #0F0F10 0%, #0C0D0E 100%);
          border-radius: 15px;
          border: 1px solid #070707;
          font-size: 14px;
          cursor: pointer;
          line-height: 30px;
        }

        .user-header-name-box {
          margin: 16px 0 18px 0;

          .user-header-name {
            font-size: 24px;
          }

          .user-header-edit {
            width: 14px;
            height: 14px;
            margin: 0 6px 0 10px;
            cursor: pointer;
          }

          .user-header-editName {
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            background: linear-gradient(131deg, #0F0F10 0%, #0C0D0E 100%);
            border-radius: 15px;
            border: 1px solid #070707;
            height: 30px;
            padding: 0 16px;
            line-height: 30px;
            margin-left: 10px;
          }

          .user-header-search {
            width: 230px;
            height: 40px;
            background: #0F1014;
            border-radius: 6px;
            cursor: pointer;
            justify-content: center;

            .user-header-search-icon {
              width: 20px;
              height: auto;
              margin-left: 15px;
            }

            .user-header-search-box {
              flex: 1;
              margin-left: 10px;

              .el-input__inner {
                background: #0F1014;
                border: none;
                color: #fff;
                padding: 0;

                &::placeholder {
                  color: #40434C;
                  font-family: 'Normal';
                }
              }
            }
          }
        }

        .user-header-list {
          font-size: 16px;
          color: #6D6D7A;
        }

        .user-header-num {
          font-size: 20px;
          margin: 0 62px 0 8px;
          line-height: 28px;
        }

        .user-header-history {
          font-size: 14px;
          color: #FDFF15;
          text-decoration: underline;
          cursor: pointer;
        }

      }
    }

    .userCenter {
      max-width: 1200px;
      margin: 50px auto 100px auto;


      .depth-list {
        // margin-bottom: 50px;
        cursor: pointer;

        .depth-list-img {
          width: 190px;
          height: 132px;
          border-radius: 10px;
        }

        .depth-list-detail {
          flex: 1;
          margin-right: 50px;

          .depth-list-title {
            font-size: 18px;
            line-height: 30px;
          }

          .depth-list-content {
            font-size: 14px;
            color: #ABABBE;
            line-height: 24px;
            margin: 16px 0;
          }

          .depth-list-user {
            .depth-list-photo {
              width: 24px;
              height: 24px;
              border-radius: 6px;
            }

            .depth-list-username {
              font-size: 12px;
              line-height: 18px;
              margin: 0 20px 0 4px;
            }

            .depth-list-time {
              font-size: 14px;
              color: #6D6D7A;
              line-height: 20px;
            }

            .depth-list-btn {
              height: 22px;
              font-size: 14px;
              line-height: 22px;
              padding: 0 10px;
              background: #0C0D0E;
              border-radius: 4px;
              border: 1px solid #070707;
              color: #fff;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }

  .depthDetail-box::-webkit-scrollbar {
    display: none;
  }

}

// 修改用户昵称
.editUsername {
  width: 400px;
  height: 232px;
  background: #131417;
  box-shadow: 0px 0px 22px 3px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  border: 1px solid #0B0B0B;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -116px;
  margin-left: -200px;
  z-index: 94;
  text-align: center;

  .editUsername-title {
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    padding-top: 20px;
  }

  .editUsername-input {
    margin: 30px auto 40px auto !important;
    width: 340px;
    height: 48px;

    .el-input__inner {
      background: #0F1012 !important;
      border: none;
      padding: 0;
      margin: 0;
      font-size: 14px;
      color: #fff;
      font-family: 'Bold';
      width: 340px;
      height: 48px;
      border-radius: 8px;
      padding: 0 20px;

      &::placeholder {
        color: #6D6D7A;
        font-family: 'Normal';
      }
    }
  }

  .editUsername-footer {
    justify-content: center;

    .editUsername-btn {
      width: 160px;
      height: 42px;
      border-radius: 12px;
      opacity: 0.9;
      backdrop-filter: blur(36px);
      text-align: center;
      line-height: 42px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
