<template>
  <div>
    <div class="header">
      <div class="header-detail">
        <router-link to="/">
          <img class="header-detail-logo" src="@/assets/img/index/intro_logo.png" alt="">
        </router-link>
        <div class="header-nav">
          <div class="header-nav-list flex" v-for="(item, index) in $t('header.navList')" :key="index"
            :class="{ 'header-nav-active': item.path == navIndex }" @click="navChange(item.path)"
            :style="{ marginRight: $t('state') == 0 ? '40px' : '20px' }">
            <p :style="{ color: item.path == navIndex ? '#222222' : '' }" class="header-nav-name">{{ item.name }}</p>
          </div>
        </div>
        <img @click="ShowBaseModal" class="header-ai" src="@/assets/img/home/ai_img.png" alt="">
        <!-- <div class="header-search flex">
          <img class="header-search-icon" src="@/assets/img/common/search_icon.png" alt="">
          <el-input class="header-search-box" v-model="search" placeholder="您想要找点什么吗？"></el-input>
        </div> -->
        <div v-show="userInfo" @click="createShow = true" class="header-list flex" style="width: auto;padding: 0 12px;">
          <img class="header-list-icon" src="@/assets/img/home/release_icon.png" alt="">
          <p class="header-list-name">{{ $t('header.Publish') }}</p>
        </div>
        <div @mouseover="languageState = true" @mouseleave="languageState = false"
          style="height: 100px;display: flex;align-items: center;">
          <div class="header-list flex">
            <img class="header-list-icon" src="@/assets/img/home/language_icon.png" alt="">
            <transition name="el-zoom-in-top">
              <div v-show="languageState" class="header-list-user" style="right: -50px;">
                <div class="header-list-user-box">
                  <div @click="setLanguage($t('zn'))" class="header-user-list flex" style="justify-content: center;">
                    <p :style="{ color: $t('state') == '0' ? '#FDFF15' : '' }" class="header-user-list-language">中文</p>
                  </div>
                  <div @click="setLanguage($t('en'))" class="header-user-list flex" style="justify-content: center;">
                    <p :style="{ color: $t('state') == '1' ? '#FDFF15' : '' }" class="header-user-list-language">English
                    </p>
                  </div>
                  <div :style="{ top: $t('state') == '0' ? '4px' : '50px' }" class="header-user-layer"></div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <!-- <div class="header-list flex" @click="taskShow = true">
          <img class="header-list-icon" src="@/assets/img/home/task_icon.png" alt="">
        </div> -->
        <div @mouseover="headerUserState = true" @mouseleave="headerUserState = false"
          style="height: 100px;display: flex;align-items: center;">
          <div class="header-list flex">
            <img @click="petraFun" class="header-list-icon" src="@/assets/img/home/user_icon.png" alt="">
            <div v-if="userInfo">
              <transition name="el-zoom-in-top">
                <div v-show="headerUserState" class="header-list-user">
                  <div class="header-list-user-box">
                    <div @click="userShow = true" @mouseover="userOver(0)" class="header-user-list flex">
                      <img class="header-user-list-photo" :src="userInfo.userpic" alt="">
                      <p class="header-user-list-address">{{ userInfo.username ? userInfo.username : '' | nameFilter }}
                      </p>
                    </div>
                    <div @click="logout" @mouseover="userOver(1)" class="header-user-list flex">
                      <img class="header-user-list-icon" src="@/assets/img/home/logOut_icon.png" alt="">
                      <p class="header-user-list-address">{{ $t('header.logOut') }}</p>
                    </div>
                    <div :style="{ top: headerUserShow == 0 ? '4px' : '50px' }" class="header-user-layer"></div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-zw"></div>
    <!-- 安装Petra -->
    <div v-if="petraShow">
      <div @click="petraShow = false" class="layer"></div>
      <div class="petra">
        <img @click="petraShow = false" class="petra-close" src="@/assets/img/common/common_close.png" alt="">
        <img class="petra-logo" src="@/assets/img/common/petra.png" alt="">
        <p class="petra-name">Petra Aptos Wallet</p>
        <p class="petra-title">{{ $t('header.msg2') }}</p>
        <a class="petra-link"
          href="https://chrome.google.com/webstore/detail/petra-aptos-wallet/ejjladinnckdgjemekebdpeokbikhfci?hl=encategory=extensions"
          target="_blank">
          {{ $t('header.msg3') }}
        </a>
      </div>
    </div>
    <!-- 我的任务 -->
    <my-tasks :show="taskShow" @dialogClose="taskShow = false"></my-tasks>
    <!-- 发布文章 -->
    <create-depth :token="post_token" :show="createShow"
      @dialogClose="createShow = false, post_token = ''"></create-depth>
    <!-- 个人中心 -->
    <div v-if="userInfo">
      <user-center :show="userShow" @dialogClose="userShow = false" @edit="editDepth"></user-center>
    </div>

  </div>
</template>

<script>
import { mapMutations } from "vuex";
import myTasks from "@/views/task/myTasks.vue";
import createDepth from "@/views/user/createDepth.vue";
import userCenter from "@/views/user/userCenter.vue";
import { wLogin } from '@/api/api'
export default {
  filters: {
    nameFilter(name) {
      if (name.length > 10) {
        let a = name.slice(0, 4)
        let b = name.slice(62, 66)
        return a + '****' + b;
      } else {
        return name;
      }

    },
  },
  name: 'header-pc',
  components: {
    myTasks, createDepth, userCenter
  },
  data() {
    return {
      navIndex: 0,
      search: '',
      headerUserShow: 0,
      headerUserState: false, //用户信息
      petraShow: false,//钱包
      languageState: false,//语言
      taskShow: false, //我的任务
      createShow: false, //发布文章
      userShow: false, //个人中心
      userInfo: {},
      post_token: '',//深度文token
    };
  },
  watch: {
    $route(v) {
      this.navIndex = v.path
    },
    isUserInfo: {
      handler(nval) {
        this.userInfo = nval
      }
    }
  },
  mounted() {
    // if (JSON.parse(localStorage.getItem('auth'))) {
    //   this.userInfo = JSON.parse(localStorage.getItem('auth'))
    // }
    this.navIndex = this.$route.fullPath
    this.GetUserInfo()
  },
  computed: {
    isUserInfo() {
      this.userInfo = this.$store.state.userInfo
      return this.$store.state.userInfo
    }
  },
  methods: {
    ...mapMutations(["ShowBaseModal", "CloseBaseModal", "GetUserInfo", "ClearUserInfo"]), //登录弹窗
    //切换导航
    navChange(val) {
      this.navIndex = val
      // this.$router.push(val)
      this.$router.push(val).catch(err => { })
    },
    //钱包
    async petraFun() {
      if (this.userInfo) { return }
      const isPetraInstalled = window.aptos;
      // console.log('打印信息')
      // console.log(isPetraInstalled)
      const getAptosWallet = () => {
        if ('aptos' in window) {
          return window.aptos;
        } else {
          // window.open('https://petra.app/', `_blank`); 
          // window.open('https://chrome.google.com/webstore/detail/petra-aptos-wallet/ejjladinnckdgjemekebdpeokbikhfci?hl=encategory=extensions', `_blank`);
          this.petraShow = true
        }
      };
      const wallet = getAptosWallet();
      // wallet.disconnect();
      try {
        const response = await wallet.connect();
        this.Login(response.address)
      } catch (error) {
        if (error.code == 4000) {
          this.$message.error('未找到账户');
        } else if (error.code == 4001) {
          this.$message.error('用户拒绝了请求');
        } else if (error.code == 4100) {
          this.$message.error('请求的方法和/或帐户尚未获得用户授权');
        }
      }
    },
    //用户信息鼠标移入
    userOver(val) {
      this.headerUserShow = val
    },
    setLanguage(val) {
      localStorage.setItem('languageStorage', val);
      this.$i18n.locale = val;
    },
    //登录
    Login(address) {
      let data = {
        address: address
      }
      wLogin(data).then(res => {
        localStorage.setItem("auth", JSON.stringify(res.data));
        this.$message({
          message: this.$t('common.Succeeded'),
          type: 'success'
        })
        this.GetUserInfo()
      })
    },
    //退出登录
    logout() {
      this.$confirm(this.$t('header.msg1'), {
        confirmButtonText: this.$t('common.Confirm'),
        cancelButtonText: this.$t('common.Cancel')
      })
        .then(_ => {
          localStorage.clear();
          this.ClearUserInfo()
          this.GetUserInfo()
          this.$message({
            message: '退出登录成功',
            type: 'success'
          })
        })
        .catch(_ => { });
    },
    //编辑用户深度文
    editDepth(token) {
      this.post_token = token
      this.createShow = true
    }

  }
}
</script>
<style lang="scss">
.header {
  width: 100%;
  height: 72px;
  background: #0F1014;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 89;
  border-bottom: 1px solid #060606;

  .header-detail {
    width: 100%;
    max-width: 1200px;
    height: 72px;
    display: flex;
    align-items: center;
    margin: 0 auto;

    .header-detail-logo {
      width: 92px;
      height: auto;
      cursor: pointer;
    }

    .header-nav {
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 100px;
      height: 72px;
      position: relative;

      .header-nav-list {
        position: relative;
        margin-right: 40px;
        cursor: pointer;
        padding: 0 20px;
        border-radius: 6px;
        height: 32px;

        .header-nav-name {
          font-size: 16px;
          color: #717790;
          transition: 0.2s all linear;
          line-height: 32px;
        }

      }


      .header-nav-active {
        background: linear-gradient(140deg, #9AFBE1 0%, #01D199 100%);
        // box-shadow: 0px 0px 9px 3px rgba(6, 6, 13, 0.43), inset 1px 1px 4px 0px rgba(0, 0, 0, 0.44);
        border-radius: 6px;
        border: 1px solid #060606;
        color: #222222;
      }
    }

    .header-ai {
      width: 224px;
      height: auto;
      cursor: pointer;
    }

    .header-search {
      width: 230px;
      height: 40px;
      background: #252830;
      border-radius: 6px;
      margin-left: 50px;

      .header-search-icon {
        width: 16px;
        height: auto;
        margin-left: 20px;
      }

      .header-search-box {
        flex: 1;
        margin-left: 10px;

        .el-input__inner {
          background: #252830;
          border: none;
          color: #fff;
          padding: 0;
        }
      }
    }

    .header-list {
      width: 40px;
      height: 40px;
      background: #252830;
      border-radius: 5px;
      margin-left: 20px;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .header-list-icon {
        width: 20px;
        height: auto;
      }

      .header-list-name {
        font-size: 16px;
        color: #fff;
        margin-left: 4px;
      }

      .header-list-user {
        width: 160px;
        height: 100px;
        background: #131417;
        border-radius: 8px;
        border: 1px solid #070707;
        position: absolute;
        top: 50px;
        right: 0;

        .header-list-user-box {
          width: 160px;
          height: 100px;
          position: relative;
          padding-top: 14px;

          .header-user-list {
            margin: 0 14px 24px;
            z-index: 1;
            position: relative;

            .header-user-list-photo {
              width: 24px;
              height: 24px;
              border-radius: 3px;
            }

            .header-user-list-address {
              flex: 1;
              margin-left: 4px;
              font-size: 14px;
              color: #fff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .header-user-list-language {
              flex: 1;
              text-align: center;
            }

            .header-user-list-icon {
              width: 16px;
              height: 16px;
              margin: 0 6px 0 2px;
            }
          }

          .header-user-layer {
            width: 150px;
            height: 42px;
            background: #0F1014;
            position: absolute;
            left: 4px;
            border-radius: 6px;
            border: 1px solid #060606;
            transition: all 0.3s;
          }
        }
      }
    }
  }
}

.header-zw {
  width: 100%;
  height: 72px;
}

//petra
.petra {
  width: 400px;
  height: 258px;
  background: #131417;
  box-shadow: 0px 0px 22px 3px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  border: 1px solid #0B0B0B;
  position: fixed;
  z-index: 91;
  top: 50%;
  left: 50%;
  margin-top: -128px;
  margin-left: -200px;

  .petra-close {
    width: 10px;
    height: auto;
    display: block;
    margin: 16px 16px 14px auto;
    cursor: pointer;
  }

  .petra-logo {
    width: 54px;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .petra-name {
    font-size: 16px;
    color: #fff;
    text-align: center;
    font-family: 'Bold';
    margin: 20px 0;
  }

  .petra-title {
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
  }

  .petra-link {
    font-size: 16px;
    color: #43EEC4;
    text-align: center;
    margin-top: 20px;
    display: block;
    text-decoration: underline;
  }
}
</style>
